import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import Input from "../../components/FormikComponents/Input";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";

interface UserObj {
  product_name: string;
  fpo_id: string;
  quantity: number;
  sp_id: string;
  updated_at: string;
}

const AllProductTransfer = () => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    {
      Header: "Product",
      accessor: "product_name",
    },
    {
      Header: "FPO Assigned",
      accessor: "fpo_id",
    },
    {
      Header: "Quantity",
      accessor: (row: { quantity: number }) => `${row.quantity} kg`,
    },
    {
      Header: "Product Batch ID",
      accessor: "sp_id",
    },
    {
      Header: "Date Assigned",
      accessor: "updated_at",
      Cell: ({ value }: any) => {
        return new Date(value).toLocaleString();
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const initialValues = {
    start_date: "",
    end_date: "",
  };

  const getProductTransfer = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/suncarbon_product/transfer/all`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }
    );
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    [
      "getProductTransfer",
      pageIndex,
      cPageSize,
      cSortBy,
      desc,
      q,
      startDate,
      endDate,
    ],
    getProductTransfer
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right flex items-center flex-col">
          <div className="flex">
            <div className="mr-28 pr-0 font-bold">Start Date</div>
            <div className="mr-24  pr-0 font-bold">End Date</div>
          </div>
          <div className="flex items-center mt-3">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                
              }}
            >
              {(props) => (
                <Form className="flex gap-x-4">
                  <Input
                    label=""
                    type="date"
                    name="start_date"
                    id="start_date"
                    className="border rounded-xl border-green-500 px-2 py-1.5 w-32"
                    onChange={(e) => {
                      props.setFieldValue("start_date", e.target.value);
                      setStartDate(e.target.value);
                    }}
                  />
                  <Input
                    label=""
                    type="date"
                    name="end_date"
                    id="end_date"
                    className="border rounded-xl border-green-500 px-2 py-1.5 w-32"
                    onChange={(e) => {
                      props.setFieldValue("end_date", e.target.value);
                      setEndDate(e.target.value);
                    }}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllProductTransfer;
