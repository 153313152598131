import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import useEscClose from "../../hooks/useEscClose";
import { hideModal } from "../../redux/features/modalSlice";
import { ModalObj } from "../../types";
import {
  ADD_ARTISAN_MODAL,
  ADD_BIOMASS_COMPOSE_MODAL,
  ADD_BUYER_MODAL,
  ADD_CATEGORY_MODAL,
  ADD_COMPOST_MATERIAL,
  ADD_CROP_MODAL,
  ADD_CROP_VARIETY_MODAL,
  ADD_FERTILISER_COMBINATION_MODAL,
  ADD_FERTILISER_MODAL,
  ADD_FPO_MODAL,
  ADD_ICAR_MODAL,
  ADD_MEASURING_VESSEL_MODAL,
  ADD_NEW_PROJECT_MODAL,
  ADD_NOTE_MODAL,
  ADD_PYROLYSIS_KILN_MODAL,
  ADD_SEED_TYPE_MODAL,
  ADD_SEED_VARIETY_MODAL,
  ADD_SELLER_MODAL,
  ADD_SOIL_PIT_MODAL,
  APPROVE_ARTISAN_MODAL,
  ASSIGN_BIOMASS_MODAL,
  ASSIGN_FPO_MODAL,
  ASSIGN_PROJECT_MODAL,
  ASSIGN_PRODUCT_TO_FPO_MODAL,
  EDIT_ALL_ARTISAN_MODAL,
  EDIT_ALL_CATEGORIES_MODAL,
  EDIT_ALL_CROPS_MODAL,
  EDIT_ALL_FERTILISERS_COMB_MODAL,
  EDIT_ALL_FERTILISERS_MODAL,
  EDIT_BIOMASS_CONFIG_MODAL,
  EDIT_FPO_MODAL,
  EDIT_ICAR_MODAL,
  EDIT_MEASURING_VESSEL_MODAL,
  EDIT_PRODUCT_MODAL,
  EDIT_PYROLYSIS_KILN_MODAL,
  EDIT_SOIL_PIT_MODAL,
  EDIT_USER_MODAL,
  EDIT_BUYER_MODAL,
  FORMULATION_FORM_MODAL,
  FPO_ONBOARDING_FORM,
  SELL_TO_BUYER,
  VIEW_ARTISAN_BATCHES_DETAIL,
  VIEW_ARTISAN_OR_PROJECT_MODAL,
  VIEW_ASSIGNED_ARTISAN,
  VIEW_BIOCHAR_MODAL,
  VIEW_FPO_MODAL,
  VIEW_PREPARATION_MODAL,
  VIEW_PRODUCT_FORMULATION_MODAL,
  VIEW_PYROLYSIS_MODAL,
  VIEW_SEQUESTRATION_MODAL
} from "../../types/constants";
import AddArtisanModal from "../ModalComponents/AddArtisanModal";
import AddBiomassComposeModal from "../ModalComponents/AddBiomassComposeModal";
import AddBuyerModal from "../ModalComponents/AddBuyerModal";
import AddCategoryModal from "../ModalComponents/AddCategoryModal";
import AddCompostMaterialModal from "../ModalComponents/AddCompostMaterialModal";
import AddCropModal from "../ModalComponents/AddCropModal";
import AddCropVarietyModal from "../ModalComponents/AddCropVarietyModal";
import AddFertiliserCombinationModal from "../ModalComponents/AddFertiliserCombinationModal";
import AddFertiliserModal from "../ModalComponents/AddFertiliserModal";
import AddICARModal from "../ModalComponents/AddICARModal";
import AddMesuringVesselModal from "../ModalComponents/AddMeasuringVesselModal";
import AddNewProjectModal from "../ModalComponents/AddNewProjectModal";
import AddNoteModal from "../ModalComponents/AddNoteModal";
import AddPyrolysisKilnModal from "../ModalComponents/AddPyrolysisKilnModal";
import AddSeedTypeModal from "../ModalComponents/AddSeedTypeModal";
import AddSeedVarietyModal from "../ModalComponents/AddSeedVarietyModal";
import AddSellerModal from "../ModalComponents/AddSellerModal";
import AddSoilPitModal from "../ModalComponents/AddSoilPitModal";
import ApproveArtisanModal from "../ModalComponents/ApproveArtisanModal";
import AssignBiomassModal from "../ModalComponents/AssignBiomassModal";
import AssignFPOModal from "../ModalComponents/AssignFPOModal";
import AssignProjectModal from "../ModalComponents/AssignProjectModal";
import AssignProductToFPOModal from "../ModalComponents/AssignProductToFPOModal";
import EditAllArtisanModal from "../ModalComponents/EditAllArtisanModal";
import EditAllCategoriesModal from "../ModalComponents/EditAllCategoriesModal";
import EditAllCropsModal from "../ModalComponents/EditAllCropsModal";
import EditAllFerilisersCombModal from "../ModalComponents/EditAllFertilisersCombModal";
import EditAllFertilisersModal from "../ModalComponents/EditAllFertilizersModal";
import EditBiomassConfigModal from "../ModalComponents/EditBiomassConfigModal";
import EditFPOModal from "../ModalComponents/EditFPOModal";
import EditICARModal from "../ModalComponents/EditICARModal";
import EditMeasuringVesselModal from "../ModalComponents/EditMeasuringVesselModal";
import EditProductModal from "../ModalComponents/EditProductModal";
import EditPyrolysisKilnModal from "../ModalComponents/EditPyrolysisKilnModal";
import EditSoilPitModal from "../ModalComponents/EditSoilPitModal";
import EditUserModal from "../ModalComponents/EditUserModal";
import EditBuyerModal from "../ModalComponents/EditBuyerModal";
import FPOOnboardedForm from "../ModalComponents/FPOOnboardedForm";
import FormulationFormModal from "../ModalComponents/FormulationFormModal";
import SellToBuyer from "../ModalComponents/SellToBuyerModal";
import ViewArtisanBatches from "../ModalComponents/ViewArtisanBatches";
import ViewArtisanOrProjectModal from "../ModalComponents/ViewArtisanOrProjectModal";
import ViewBioCharModal from "../ModalComponents/ViewBioCharModal";
import ViewFPOModal from "../ModalComponents/ViewFPOModal";
import ViewPreparationModal from "../ModalComponents/ViewPreparationModal";
import ViewProductFormulationModal from "../ModalComponents/ViewProductFormulation";
import ViewPyrolysisModal from "../ModalComponents/ViewPyrolysisModal";
import ViewSequestrationModal from "../ModalComponents/ViewSequestrationModal";
import ViewAssignedArtisan from "../ModalComponents/ViewAssignedArtisan";

interface Props extends ModalObj { }

const Modal = ({
  modalType,
  modalProps,
  modalTitle,
  showCloseBtn,
  bgColor,
  textColor,
}: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const ref = useEscClose(() => handleClose());

  let Component: React.FC<any> | null = null;

  switch (modalType) {
    case ADD_CATEGORY_MODAL:
      Component = AddCategoryModal;
      break;
    case ADD_CROP_MODAL:
      Component = AddCropModal;
      break;
    case EDIT_PRODUCT_MODAL:
      Component = EditProductModal;
      break;
    case EDIT_USER_MODAL:
      Component = EditUserModal;
      break;
    case EDIT_BUYER_MODAL:
      Component = EditBuyerModal;
      break;
    case EDIT_ALL_CROPS_MODAL:
      Component = EditAllCropsModal;
      break;
    case EDIT_ALL_ARTISAN_MODAL:
      Component = EditAllArtisanModal;
      break;
    case EDIT_ALL_CATEGORIES_MODAL:
      Component = EditAllCategoriesModal;
      break;
    case EDIT_MEASURING_VESSEL_MODAL:
      Component = EditMeasuringVesselModal;
      break;
    case EDIT_SOIL_PIT_MODAL:
      Component = EditSoilPitModal;
      break;
    case EDIT_ALL_FERTILISERS_MODAL:
      Component = EditAllFertilisersModal;
      break;
    case EDIT_ALL_FERTILISERS_COMB_MODAL:
      Component = EditAllFerilisersCombModal;
      break;
    case EDIT_BIOMASS_CONFIG_MODAL:
      Component = EditBiomassConfigModal;
      break;
    case EDIT_PYROLYSIS_KILN_MODAL:
      Component = EditPyrolysisKilnModal;
      break;
    case EDIT_FPO_MODAL:
      Component = EditFPOModal;
      break;
    case VIEW_FPO_MODAL:
      Component = ViewFPOModal;
      break;
    case FPO_ONBOARDING_FORM:
      Component = FPOOnboardedForm;
      break;
    case FORMULATION_FORM_MODAL:
      Component = FormulationFormModal;
      break;
    case VIEW_PRODUCT_FORMULATION_MODAL:
      Component = ViewProductFormulationModal;
      break;
    case ASSIGN_PRODUCT_TO_FPO_MODAL:
      Component = AssignProductToFPOModal;
      break;
    case ADD_FPO_MODAL:
      Component = ViewFPOModal;
      break;
    case ADD_SEED_TYPE_MODAL:
      Component = AddSeedTypeModal;
      break;
    case ADD_SEED_VARIETY_MODAL:
      Component = AddSeedVarietyModal;
      break;
    case ADD_ICAR_MODAL:
      Component = AddICARModal;
      break;
    case EDIT_ICAR_MODAL:
      Component = EditICARModal;
      break;
    case ADD_CROP_VARIETY_MODAL:
      Component = AddCropVarietyModal;
      break;
    case ADD_FERTILISER_MODAL:
      Component = AddFertiliserModal;
      break;
    case ADD_FERTILISER_COMBINATION_MODAL:
      Component = AddFertiliserCombinationModal;
      break;
    case VIEW_PREPARATION_MODAL:
      Component = ViewPreparationModal;
      break;
    case VIEW_PYROLYSIS_MODAL:
      Component = ViewPyrolysisModal;
      break;
    case VIEW_BIOCHAR_MODAL:
      Component = ViewBioCharModal;
      break;
    case ADD_BIOMASS_COMPOSE_MODAL:
      Component = AddBiomassComposeModal;
      break;
    case ADD_PYROLYSIS_KILN_MODAL:
      Component = AddPyrolysisKilnModal;
      break;
    case ADD_SOIL_PIT_MODAL:
      Component = AddSoilPitModal;
      break;
    case ADD_MEASURING_VESSEL_MODAL:
      Component = AddMesuringVesselModal;
      break;
    case ASSIGN_BIOMASS_MODAL:
      Component = AssignBiomassModal;
      break;
    case VIEW_SEQUESTRATION_MODAL:
      Component = ViewSequestrationModal;
      break;
    case ADD_NEW_PROJECT_MODAL:
      Component = AddNewProjectModal;
      break;
    case VIEW_ARTISAN_OR_PROJECT_MODAL:
      Component = ViewArtisanOrProjectModal;
      break;
    case VIEW_ASSIGNED_ARTISAN:
      Component = ViewAssignedArtisan;
      break;
    case APPROVE_ARTISAN_MODAL:
      Component = ApproveArtisanModal;
      break;
    case VIEW_ARTISAN_BATCHES_DETAIL:
      Component = ViewArtisanBatches;
      break;
    case ADD_BUYER_MODAL:
      Component = AddBuyerModal;
      break;
    case ADD_SELLER_MODAL:
      Component = AddSellerModal;
      break;
    case ADD_ARTISAN_MODAL:
      Component = AddArtisanModal;
      break;
    case ADD_NOTE_MODAL:
      Component = AddNoteModal;
      break;
    case SELL_TO_BUYER:
      Component = SellToBuyer;
      break;
    case ASSIGN_FPO_MODAL:
      Component = AssignFPOModal;
      break;
    case ASSIGN_PROJECT_MODAL:
      Component = AssignProjectModal;
      break;
    case ADD_COMPOST_MATERIAL: 
      Component = AddCompostMaterialModal;
      break;
    default:
      Component = null;
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div
      className="backdrop fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full overflow-y-auto overflow-x-auto m-auto bg-black bg-opacity-60"
      style={{
        maxHeight: "100vh",
      }}
    >
      <div className="absolute top-16 flex flex-col items-center">
        <div
          className={`relative rounded modal flex flex-col`}
          style={{
            maxWidth: "80rem",
            background: bgColor ? bgColor : "#ffffff",
            color: textColor ? textColor : "inherit",
          }}
          ref={ref}
        >
          {showCloseBtn === true && (
            <button
              onClick={handleClose}
              type="button"
              className="modal__close-btn absolute right-2 z-10 top-3"
            >
              <MdClose color={textColor ? textColor : "inherit"} size={21} />
            </button>
          )}

          {modalTitle && (
            <div className="modal__title">
              <h3 className="text-xl font-semibold pl-4 px-2 py-4">
                {modalTitle}
              </h3>
            </div>
          )}

          <div className={`w-full z-50 ${showCloseBtn ? "mr-4" : ""}`}>

            {Component !== null && <Component {...modalProps} />}
          </div>
        </div>
        <div className="empty-space pb-14"></div>
      </div>
    </div>
  );
};

export default Modal;
