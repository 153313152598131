import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

const stateArray = [
  { value: "", label: "Select State" },
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
];

interface UserObj {
  name: string;
  phonenumber: string;
  email: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  gstin: string;
  productCategories: string;
  companyCategory: string;
  companyName: string;
  lat?: string;
  lng?: string;
  fpo_id?: string;

  company_address?: string;
  company_email?: string;
  company_mobile?: string;
  owner_kyc_doc_url?: string;
  addressProof?: string;
  onsite_person_name?: string;
  onsite_person_mobile?: string;
  site_image_url?: string;
  biomass_type?: string;
  site_address?: string;
  company_lat?: string;
  company_lng?: string;
}

interface RowObj {
  id?: string;
  name?: string;
  phonenumber?: string;
  email?: string;
  gstin?: string;
  productCategories?: string[];
  companyCategory?: string;
  companyName?: string;
  fpo_id?: string;
  fpo_name?: string;

  addresses?: {
    address?: string;
    city?: string;
    state?: string;
    pincode?: string;
    coordinates?: string;
  }[]

  company_address?: string;
  company_email?: string;
  company_mobile?: string;
  owner_kyc_doc_url?: string;
  addressProof?: string;
  onsite_person_name?: string;
  onsite_person_mobile?: string;
  site_image_url?: string;
  biomass_type?: string;
  site_address?: string;
  company_lat?: string;
  company_lng?: string;
}

interface SellerModalProps {
  row?: RowObj
}

const AddSellerModal: React.FC<SellerModalProps> = (props: SellerModalProps) => {

  const { row } = props;


  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonError, setCommonError] = useState("");
  const [showForm, setShowForm] = useState(true);
  const queryClient = useQueryClient();
  const [role, setRole] = useState(localStorage.getItem("role"));


  props.row && console.log(props.row.addresses?.at(0)?.coordinates?.replace(/[()]/g, "").split(","))

  const initialValues: UserObj = {
    name: props?.row?.name ? props.row.name : "",
    phonenumber: props?.row?.phonenumber ? props.row.phonenumber : "",
    email: props?.row?.email ? props.row.email : "",
    address: props?.row?.addresses?.at(0)?.address ? props.row.addresses?.at(0)?.address as string : "",
    city: props?.row?.addresses?.at(0)?.city ? props.row.addresses?.at(0)?.city as string : "",
    state: props?.row?.addresses?.at(0)?.state ? props.row.addresses?.at(0)?.state as string : "",
    pincode: props?.row?.addresses?.at(0)?.pincode ? props.row.addresses?.at(0)?.pincode as string : "",
    gstin: props?.row?.gstin ? props.row.gstin : "",
    productCategories: props?.row?.productCategories ? props.row.productCategories?.at(0) as string : "",
    companyCategory: props?.row?.companyCategory ? props.row.companyCategory : "",
    companyName: props?.row?.companyName ? props.row.companyName : "",
    lat: props?.row?.addresses?.at(0)?.coordinates ? props?.row?.addresses?.at(0)?.coordinates?.replace(/[()]/g, "").split(",")?.at(0) : "0",
    lng: props?.row?.addresses?.at(0)?.coordinates ? props?.row?.addresses?.at(0)?.coordinates?.replace(/[()]/g, "").split(",")[1] : "0",
    fpo_id: props?.row?.fpo_id ? props.row.fpo_id : "",

    // in case of biomass only
    company_address: props?.row?.company_address ? props.row.company_address : "",
    company_email: props?.row?.company_email ? props.row.company_email : "",
    company_mobile: props?.row?.company_mobile ? props.row.company_mobile : "",
    onsite_person_name: props?.row?.onsite_person_name ? props.row.onsite_person_name : "",
    onsite_person_mobile: props?.row?.onsite_person_mobile ? props.row.onsite_person_mobile : "",
    biomass_type: props?.row?.biomass_type ? props.row.biomass_type : "",
    site_address: props?.row?.site_address ? props.row.site_address : "",
    company_lat: props?.row?.company_lat ? props.row.company_lat : "0",
    company_lng: props?.row?.company_lng ? props.row.company_lng : "0",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
      .length(10, "Phone Number must be exactly 10 digits")
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Pin Code must be only digits")
      .length(6, "Pin Code must be exactly 6 digits")
      .required("Pin Code is required"),
    gstin: Yup.string()
      .optional()
      .min(12, "GST Number must be 12 characters long")
      .max(15, "GST Number must be 15 characters long"),
    productCategories: Yup.string().required(
      "Product Categories are required"
    ),
    companyCategory: Yup.string().required("Company Category is required"),
    companyName: Yup.string().required("Company / Firm / Individual Name is required"),

    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
  });

  const [files, setfiles] = useState<any>();
  const [kycDoc, setKycDoc] = useState<any>();
  const [siteImage, setSiteImage] = useState<any>();


  const [productCategoriesList, setProductCategoriesList] = useState<
    { label: string; value: string }[] | null
  >(null);

  const getProductCategories = async () => {
    const { data } = await axiosInstance.get(
      "/products/categories"
    );;

    const productCatList = data.data.map((_: any) => {
      return {
        label: _.category,
        value: _.category,
      };
    });
    setProductCategoriesList(productCatList);
    return data?.data;
  };

  useEffect(() => {
    getProductCategories();
  }, [])



  const handleSubmit = useCallback(
    async (
      values: UserObj,
      {
        setSubmitting,
      }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      // setIsSubmitting(true);
      setCommonError("");

      try {
        if (!values.lat || !values.lng) {
          setCommonError("Please enter valid Latitude and Longitude");
          return;
        }

        let obj :RowObj |any= {};

        if (initialValues.name !== values.name && values.name) {
          obj.name = values.name;
        }
        if (initialValues.phonenumber !== values.phonenumber && values.phonenumber) {
          obj.phonenumber = values.phonenumber;
        }
        if (initialValues.email !== values.email && values.email) {
          obj.email = values.email;
        }
        if (initialValues.address !== values.address && values.address) {
          // obj.address = values.address;
          obj.addresses = [{ ...obj.addresses?.[0], address: values.address }];
        }
        if (initialValues.city !== values.city && values.city) {
          obj.addresses = [{ ...obj.addresses?.[0], city: values.city }];
        }
        if (initialValues.state !== values.state && values.state) {
          obj.addresses = [{ ...obj.addresses?.[0], state: values.state }];
        }
        if (initialValues.pincode !== values.pincode && values.pincode) {
          obj.addresses = [{ ...obj.addresses?.[0], pincode: values.pincode }];
        }
        if (initialValues.gstin !== values.gstin && values.gstin) {
          obj.gstin = values.gstin;
        }
        if (initialValues.productCategories !== values.productCategories && values.productCategories) {
          obj.productCategories = [values.productCategories];
        }
        if (initialValues.companyCategory !== values.companyCategory && values.companyCategory) {
          obj.companyCategory = values.companyCategory;
        }
        if (initialValues.companyName !== values.companyName && values.companyName) {
          obj.companyName = values.companyName;
        }
        if (initialValues.lat !== values.lat && values.lat) {
          obj.addresses = [{ ...obj.addresses?.[0], coordinates: `(${values.lat},${values.lng})` }];
        }
        if (initialValues.lng !== values.lng && values.lng) {
          obj.addresses = [{ ...obj.addresses?.[0], coordinates: `(${values.lat},${values.lng})` }];
        }
        if (initialValues.fpo_id !== values.fpo_id && values.fpo_id) {
          obj.fpo_id = values.fpo_id;
        }
        if (initialValues.company_address !== values.company_address && values.company_address) {
          obj.company_address = values.company_address;
        }
        if (initialValues.company_email !== values.company_email && values.company_email) {
          obj.company_email = values.company_email;
        }
        if (initialValues.company_mobile !== values.company_mobile && values.company_mobile) {
          obj.company_mobile = values.company_mobile;
        }
        if (initialValues.onsite_person_name !== values.onsite_person_name && values.onsite_person_name) {
          obj.onsite_person_name = values.onsite_person_name;
        }
        if (initialValues.onsite_person_mobile !== values.onsite_person_mobile && values.onsite_person_mobile) {
          obj.onsite_person_mobile = values.onsite_person_mobile;
        }
        if (initialValues.biomass_type !== values.biomass_type && values.biomass_type) {
          obj.biomass_type = values.biomass_type;
        }
        if (initialValues.site_address !== values.site_address && values.site_address) {
          obj.site_address = values.site_address;
        }
        if (initialValues.company_lat !== values.company_lat && values.company_lat) {
          obj.company_lat = values.company_lat;
        }
        if (initialValues.company_lng !== values.company_lng && values.company_lng) {
          obj.company_lng = values.company_lng;
        }

        axiosInstance.patch(`/sellers/${props?.row && props?.row?.id}`, obj, {})
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitting(false);
              setSubmitting(false);
              dispatch(
                addToast({
                  kind: SUCCESS,
                  msg: "Seller Edited Successfully!",
                })
              );
              queryClient.invalidateQueries(["getSellers"]);
              setShowForm(false);
              dispatch(hideModal());
            }
          })
          .catch((error: AxiosError) => {
            setIsSubmitting(false);
            setSubmitting(false);

            if (error.response) {
              const { msg } = error.response.data as {
                msg: string;
              };
              switch (error.response.status) {
                case 400:
                case 403:
                case 500:
                  dispatch(
                    addToast({
                      kind: ERROR,
                      msg: `Error: ${msg}`,
                    })
                  );
                  break;
                case 404:
                  dispatch(
                    addToast({
                      kind: ERROR,
                      msg: `No data found for following query`,
                    })
                  );
                  break;
                default:

                  dispatch(
                    addToast({
                      kind: ERROR,
                      msg: `Oops, something went wrong`,
                    })
                  );
                  break;
              }
            } else if (error.request) {
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: `Error: ${error.message}`,
                })
              );
            } else {
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: `Error: ${error.message}`,
                })
              );
            }
          });
      } catch (error) {

        setIsSubmitting(false);
        setSubmitting(false);
        setCommonError("Oops, something went wrong");
        return;
      }
    },
    [dispatch, queryClient, kycDoc, siteImage, files]
  );

  return (
    <>
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
          }) => (
            <Form
              className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
              style={{ minWidth: "50vw" }}
            >
              <img
                src={logo}
                alt="logo"
                height={100}
                width={100}
              />

              <Input
                label="Name"
                id="name"
                name="name"
                type="text"
                placeholder="Enter Name"
                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />

              {false && (
                <Input
                  label="Phone Number"
                  id="phonenumber"
                  name="phonenumber"
                  type="number"
                  placeholder="91 xxxxx xxxxx"
                  className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              )}

              {false && (
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="example@gmail.com"
                  className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              )}

              {false && <><div className="mb-0 w-full">
                <Input
                  label="Address"
                  id="address"
                  name="address"
                  type="text"
                  placeholder="Address"
                  className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
                <div className="flex mb-4 w-full space-x-4">
                  <Input
                    label="City"
                    id="city"
                    name="city"
                    type="text"
                    placeholder="City"
                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <div className="w-full">
                    <Select
                      options={stateArray}
                      label="State"
                      id="state"
                      name="state"
                    />
                  </div>
                  <Input
                    label="Pin Code"
                    id="pincode"
                    name="pincode"
                    type="number"
                    placeholder="Pin Code"
                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <Input
                  label="Latitude"
                  id="lat"
                  name="lat"
                  type="text"
                  placeholder="Latitude"
                  readOnly
                  className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
                <Input
                  label="Longitude"
                  id="lng"
                  name="lng"
                  type="text"

                  placeholder="Longitude"
                  readOnly
                  className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>


              <div className="w-full h-96 rounded-xl mb-5">
                <CenterMarker latName="lat" lngName="lng" />
              </div></>}


              <Input
                label="GST Number"
                id="gstin"
                name="gstin"
                type="text"
                placeholder="xxxx xxxxx"
                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />


              {productCategoriesList && (
                <Select
                  options={productCategoriesList}
                  label="Product Categories"
                  id="productCategories"
                  name="productCategories"
                  classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 mb-4"
                />
              )}


              <Input
                label="Company / Firm / Individual Name"
                id="companyName"
                name="companyName"
                type="text"
                placeholder="Enter Company / Firm / Individual Name"
                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
              <Select
                label="Company Category"
                id="companyCategory"
                name="companyCategory"
                classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                options={[
                  {
                    label: "Pvt Ltd.",
                    value: "pvtLtd",
                  },
                  {
                    label: "Individual",
                    value: "individual",
                  },
                  {
                    label: "LLP",
                    value: "llp",
                  },
                  {
                    label: "FPO",
                    value: "fpo",
                  },
                  {
                    label: "Proprietor",
                    value: "proprietor",
                  },
                ]}
              />

              {false && (
                <Input
                  label="Address Proof"
                  id="addressProof"
                  name="addressProof"
                  type="file"
                  // placeholder="Address Proof"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    if (e.target.files)
                      setfiles(e.target?.files[0]);
                  }}
                  className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              )}
              {role === "admin" && (
                <Input
                  label="FPO Unique ID"
                  id="fpo_id"
                  name="fpo_id"
                  type="text"
                  placeholder="SUN-FPO-XXXXXXXX"
                  className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              )}
              {false && values.productCategories.includes("biomass") && (
                <>
                  <p>Company Details</p>
                  <Input
                    label="Company Address"
                    id="company_address"
                    name="company_address"
                    type="text"
                    placeholder="XXX Colony, XXXXXX"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Company Email"
                    id="company_email"
                    name="company_email"
                    type="email"
                    placeholder="example@gmail.com"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Company Mobile"
                    id="company_mobile"
                    name="company_mobile"
                    type="number"
                    placeholder="xxxxx-xxxxx"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Owner KYC Doc"
                    id="owner_kyc_doc_url"
                    name="owner_kyc_doc_url"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      if (e.target.files)
                        setKycDoc(e.target?.files[0]);
                    }}
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Onsite Person Name"
                    id="onsite_person_name"
                    name="onsite_person_name"
                    type="text"
                    placeholder="eg. Abhinav"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Onsite Person Mobile"
                    id="onsite_person_mobile"
                    name="onsite_person_mobile"
                    type="number"


                    placeholder="91 xxx-xxx-xxxx"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Site Image"
                    id="site_image_url"
                    name="site_image_url"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      if (e.target.files)
                        setSiteImage(
                          e.target?.files[0]
                        );
                    }}
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />

                  <SelectDropDownAsync
                    label="Biomass Type"
                    id="biomass_type"
                    name="biomass_type"
                    classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    loadOptions={async (
                      inferString: string
                    ) => {
                      try {
                        const response =
                          await axiosInstance.get(
                            "/biomass_composition/all"
                          );
                        let requiredData: any = [];
                        for (
                          let i = 0;
                          i <
                          response.data.data.length;
                          i++
                        ) {
                          if (true) {
                            requiredData.push({
                              label: response.data
                                .data[i]
                                .biomass_name,
                              value: response.data
                                .data[i]
                                .biomass_composition_id,
                            });
                          }
                        }
                        return requiredData;
                      } catch (error) {
                        return [
                          {
                            label: "Error. Something went wrong!!",
                            value: "error",
                          },
                        ];
                      }
                    }}
                  />
                  <Input
                    label="Site Address"
                    id="site_address"
                    name="site_address"
                    type="text"
                    placeholder="ABC123"
                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Company Latitude"
                    id="company_lat"
                    name="company_lat"
                    type="text"
                    placeholder="Latitude"
                    readOnly

                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />
                  <Input
                    label="Company Longitude"
                    id="company_lng"
                    name="company_lng"
                    type="text"
                    placeholder="Longitude"
                    readOnly

                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  />

                  <div className="w-full h-96 rounded-xl mb-10">

                    <CenterMarker latName="company_lat" lngName="company_lng" />

                  </div>
                </>
              )}

              {commonError && (
                <div className="common-error mb-2 text-center">
                  <ErrorBox msg={commonError} />
                </div>
              )}

              <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                <SubmitBtn
                  text="Update Seller"
                  isSubmitting={isSubmitting}
                  classes="text-sm"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddSellerModal;
