import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toasts from "./components/Toasts/Toasts";
import { RootState } from "./redux/app";

import Modal from "./components/Modal/Modal";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/auth/Login";
import PrivateRoute from "./PrivateRoute";

const App = () => {
  const modal = useSelector((state: RootState) => state.modal);

  return (
    <div>
      <Toasts />
      <ToastContainer position="top-right" />

      <BrowserRouter>
        <Routes>
          {/* /auth */}
          <Route path="auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />

            <Route
              path="*"
              element={<Navigate to="/auth/login" replace={true} />}
            />
          </Route>

          {/* /* */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          />
        </Routes>
        
      </BrowserRouter>

      {/* modal */}
      {modal.modalType !== null && <Modal {...modal} />}
    </div>
  );
};

export default App;
