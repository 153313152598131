import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_CROP_MODAL,
  ADD_CROP_VARIETY_MODAL,
  ADD_ICAR_MODAL,
  ADD_SEED_TYPE_MODAL,
  ADD_SEED_VARIETY_MODAL,
  EDIT_ALL_CROPS_MODAL,
  EDIT_ICAR_MODAL,
} from "../../types/constants";
import { FaPen } from "react-icons/fa";

interface UserObj {
  id: string;
  category: string;
}

const AllCrops = () => {
  const queryClient = useQueryClient();

  const ALL_USERS_COLUMNS = [
    {
      Header: "Crop ID",
      accessor: "id",
      Cell: ({ value, row }: any) => {
        return (
          <div
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ICAR_MODAL,
                  modalTitle: "Edit ICAR",
                  modalProps: {
                    cropId: value,
                    cropType: row.original.type.toLowerCase(),
                  },
                })
              );
            }}
            className="cursor-pointer"
          >
            <p>{value}</p>
          </div>
        );
      },
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }: any) => {
        return (
          <div
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ICAR_MODAL,
                  modalTitle: "Edit ICAR",
                  modalProps: {
                    cropId: row.original.id,
                    cropType: row.original.type.toLowerCase(),
                  },
                })
              );
            }}
            className="cursor-pointer"
          >
            <p>{value}</p>
          </div>
        );
      },
    },

    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value, row }: any) => {
        return (
          <div
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ICAR_MODAL,
                  modalTitle: "Edit ICAR",
                  modalProps: {
                    cropId: row.original.id,
                    cropType: row.original.type.toLowerCase(),
                  },
                })
              );
            }}
            className="cursor-pointer"
          >
            <p>{value}</p>
          </div>
        );
      },
    },

    {
      Header: "Seed Type",
      accessor: "cropId",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_SEED_TYPE_MODAL,
                  modalTitle: "Add Seed Type",
                  modalProps: {
                    cropId: value,
                    type: row.original.type.toLowerCase(),
                    name: row.original.name,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            {/* Add Seed Type< */}
            <span className="flex items-center">
              <span className="mr-1"> Add Seed Type</span> <MdAdd size={17} />
            </span>
          </button>
        );
      },
    },

    {
      Header: "Crop Variety",
      accessor: "createdAt",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CROP_VARIETY_MODAL,
                  modalTitle: "Add Crop Variety",
                  modalProps: {
                    cropId: row.original.id,
                    type: row.original.type,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <span className="flex items-center">
              <span className="mr-1"> Add Crop Variety</span> <MdAdd size={17} />
            </span>

          </button>
        );
      },
    },

    {
      Header: "ICAR",
      accessor: "updatedAt",
      Cell: ({ value, row }: any) => {
        return (
          <div className="flex justify-center items-center bg-green-600 text-white py-3">
            <button
              className="flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: ADD_ICAR_MODAL,
                    modalTitle: "Add ICAR",
                    modalProps: {
                      cropId: row.original.id,
                      cropType: row.original.type,
                    },
                  })
                );
              }}
            >
              <span className="mr-1">Add ICAR</span> <MdAdd size={17} />
            </button>
          </div>
        );
      },
    },


    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ALL_CROPS_MODAL,
                  modalTitle: "Edit Crop",
                  modalProps: {
                    cropId: row?.original.id,
                    name: row?.original.name,
                    type: row?.original.type,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },

  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getCrops = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/farming/crops`);
    
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getCrops", pageIndex, cPageSize, cSortBy, desc, q], getCrops);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right">
          <div className="flex flex-row gap-4">
            <button
              className="btn-primary flex items-center"
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: ADD_CROP_MODAL,
                    modalTitle: "Add a New Crop",
                  })
                );
              }}
            >
              <span className="mr-1">Add</span> <MdAdd size={17} />
            </button>

            <button
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: ADD_SEED_VARIETY_MODAL,
                    modalTitle: "Add Seed Variety",
                  })
                );
              }}
              className="btn-primary bg-green-600 text-white px-2 py-3  flex items-center "
            >
              Add Seed Variety <MdAdd size={17} />
            </button>
          </div>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllCrops;
