import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_BIOMASS_COMPOSE_MODAL,
    EDIT_BIOMASS_CONFIG_MODAL,
    VIEW_ASSIGNED_ARTISAN,
} from "../../types/constants";
import { FaPen, FaEye } from "react-icons/fa";


interface BiomassObj {
    biomass_composition_id: number;
    biomass_name: string;
    biomass_blend: {
        cotton_stock: number;
        rice_straw: number;
    };
    average_biomass_diameter: string;
    average_biomass_length: string;
    biomass_photo: string;
    artisan_id_assigned: string | null;
}

const formatKey = (key: string): string => {
    return key
        .replace(/[^a-zA-Z0-9\s]/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .split(" ")
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
};

const BiomassComposition = () => {
    const ALL_USERS_COLUMNS = [
        {
            Header: "Biomass Id",
            accessor: "biomass_composition_id",
        },
        {
            Header: "Biomass Name",
            accessor: "biomass_name",
        },
        {
            Header: "Biomass Blend Details",
            accessor: "biomass_blend",
            Cell: ({ value }: any) => {
                return (
                    <div className="flex flex-col gap-2">
                        {Object.entries(value).map(([key, value]: any) => {
                            return (
                                <div className="flex flex-row ml-10 min-w-[130px] max-w-[190px]">
                                    <p className="flex flex-1 justify-start">
                                        {formatKey(key)}:
                                    </p>
                                    <p className="flex flex-1 justify-end">
                                        {value}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: "Avg diameter",
            accessor: "average_biomass_diameter",
        },
        {
            Header: "Avg length",
            accessor: "average_biomass_length",
        },
        {
            Header: "Assigned Artisan",
            accessor: "artisan_id_assigned",
            Cell: ({ value, row }: any) => {
                const filterData = row?.original.artisans.filter(
                    (artisan: any) => artisan !== null
                );
                return (
                    <>
                        {filterData.length > 0 ? (
                            <button
                                className="bg-green-600 text-white px-2 py-3"
                                onClick={() => {
                                    dispatch(
                                        showModal({
                                            modalType: VIEW_ASSIGNED_ARTISAN,
                                            modalTitle: "View Assigned Artisan",
                                            modalProps: {
                                                artisans: filterData,
                                            },
                                        })
                                    );
                                }}
                            >
                                <span className="mr-1">View Artisan List</span>
                            </button>
                        ) : (
                            <p>No artisans assigned</p>
                        )}
                    </>
                );
            },
        },
        {
            Header: "Biomass Img",
            accessor: "biomass_photo",
            Cell: ({ value }: any) => {
                return (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                );
            },
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: ({ value, row }: any) => {
                return (
                    <button
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: EDIT_BIOMASS_CONFIG_MODAL,
                                    modalTitle: "Edit Biomass Config",
                                    modalProps: {
                                        biomassConfigId:
                                            row?.original
                                                .biomass_composition_id,
                                        biomassName: row?.original.biomass_name,
                                        cottonStock:
                                            row?.original.biomass_blend
                                                .cotton_stock,
                                        riceStraw:
                                            row?.original.biomass_blend
                                                .rice_straw,
                                        avgBiomassDia:
                                            row?.original
                                                .average_biomass_diameter,
                                        avgBiomassLen:
                                            row?.original
                                                .average_biomass_length,
                                        biomassPhoto:
                                            row?.original.biomass_photo,
                                        artisanIdAssigned:
                                            row?.original.artisan_id_assigned,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        <FaPen />
                    </button>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [, setSelectedRows] = useState([]);

    let component = null;

    const getAllBiomassComposition = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(`/biomass_composition/all`);
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        BiomassObj[] | undefined,
        any,
        BiomassObj[],
        any[]
    >(
        ["getAllBiomassComposition", pageIndex, cPageSize, cSortBy, desc, q],
        getAllBiomassComposition
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => {}}
                    />
                </div>

                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_BIOMASS_COMPOSE_MODAL,
                                    modalTitle: "Add Biomass Config",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default BiomassComposition;
