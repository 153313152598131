import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_FERTILISER_COMBINATION_MODAL,
  EDIT_ALL_FERTILISERS_COMB_MODAL
} from "../../types/constants";

interface UserObj {
  id: string;
  category: string;
}

const AllFertilisersCombination = () => {
  const queryClient = useQueryClient();

  const ALL_USERS_COLUMNS = [
    {
      Header: "Fertilizer Name",
      accessor: "name",
    },
    {
      Header: "Fertilizer Id",
      accessor: "id",
    },

    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   Cell: ({ value, row }: any) => {
    //     console.table(row.original);
    //     return (
    //       <button
    //         onClick={() => {
    //           dispatch(
    //             showModal({
    //               modalType: EDIT_ALL_FERTILISERS_COMB_MODAL,
    //               modalTitle: "Edit Fertiliser Combo",
    //               modalProps: {
    //                 fertiliserCombId: row?.original.id,
    //                 name: row?.original.name,
    //                 addressId: row?.original.addressId,
    //               },
    //             })
    //           );
    //         }}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         Edit Fertiliser Combo
    //       </button>
    //     );
    //   },
    // },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getFertilisers = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(
      `/fertilizer/inorganic-fertilizer-combo/all`
    );
    
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    ["getFertilisersComb", pageIndex, cPageSize, cSortBy, desc, q],
    getFertilisers
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_FERTILISER_COMBINATION_MODAL,
                  modalTitle: "Add a Fertiliser Combination",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllFertilisersCombination;
