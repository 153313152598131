import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";

interface MeasuringVesselObj {
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume: string;
  vessel_image: string;
}

type EditMeasuringVesselModalProps = {
  measuringVesselId: number;
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume: string;
  vessel_image: string;
};

const EditMeasuringVesselModal: React.FC<EditMeasuringVesselModalProps> = ({
  measuringVesselId,
  vessel_name,
  manufacturer_name,
  vessel_type,
  volume,
  vessel_image,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImg, setNewImg] = useState<File | null>(null);
  const initialValues: MeasuringVesselObj = {
    vessel_name,
    manufacturer_name,
    vessel_type,
    volume,
    vessel_image,
  };

  const validationSchema = Yup.object({
    vessel_name: Yup.string().required("Vessel Name is required"),
    manufacturer_name: Yup.string().required("Manufacturer Name is required"),
    vessel_type: Yup.string().required("Vessel Type is required"),
    volume: Yup.string().required("Volume is required"),
  });

  const handleSubmit = useCallback(
    async (measuringVessel: MeasuringVesselObj) => {
      setIsSubmitting(true);
      try {
        let finalImageUrl = vessel_image;
        if (newImg) {
          const imgRes = await uploadImage(newImg, AZURE_STORAGE_DIRECTORY.MEASURING_VESSEL, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
          if (imgRes.data.publicUrl) {
            finalImageUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }

        const payload: Partial<MeasuringVesselObj> = {
          ...measuringVessel,
          vessel_image: finalImageUrl,
        };

        await axiosInstance.patch(
          `/measuring_vessel/${measuringVesselId}`,
          payload,
          { headers: { ContentType: "application/json" } }
        );
        
        queryClient.invalidateQueries(["getAllMeasuringVessel"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Measuring Vessel Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, measuringVesselId, queryClient, newImg, vessel_image]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <div className="flex item-center gap-x-6">
            <Input
              label="Vessel Name"
              id="vessel_name"
              name="vessel_name"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Manufacturer Name"
              id="manufacturer_name"
              name="manufacturer_name"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Vessel Type"
              id="vessel_type"
              name="vessel_type"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input label="Volume"
              id="volume"
              name="volume"
              type="text" />
          </div>

          <div className="item-center gap-x-6">
            <label
              htmlFor="vessel_image"
              className="block text-sm font-extrabold text-black"
            >
              Preview Measuring Vessel Image
            </label>
            <ImagePreview imageUrl={vessel_image}
              altText="Vessel Preview" />
          </div>

          <div className="item-center gap-x-6 mt-5">
            <Input
              label="Upload New Measuring Vessel Image"
              id="new_vessel_image"
              name="new_vessel_image"
              type="file"
              accept=".jpg,.png,.jpeg,.webp"
              onChange={(e) => {
                if (e.target.files) {
                  setNewImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditMeasuringVesselModal;
