import { useMemo } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import Checkbox from "./Checkbox";
import Table from "./Table";

const TableInstance = ({
  tableData,
  columnName,
  excludeColumnSearch,
  cPageSize,
  cSetPageSize,
  pageIndex,
  setPageIndex,
  pageCount,
  cSortBy,
  cSetSortBy,
  desc,
  setDesc,
  q,
  setSelectedRows,
  setQ,
  selectRow = false,
}) => {
  const columns = useMemo(() => columnName, []);
  const columnNameAccessors = columns.map((column) => column.accessor);
  // const data = useMemo(() => {
  //   // console.log("tableData", tableData);
  //   const tableDataNew = tableData.filter((row)=> {
  //     console.log(row, columnName)
  //     return Object.keys(row).some((key) => {
  //       console.log(key);
  //       if (excludeColumnSearch.includes(key)) {
  //         // return false;
  //       }
  //       if(key.split('.').length > 1){
  //         let keys = key.split('.');
  //         let newKey = row[keys[0]][keys[1]];
  //         return String(newKey).toLowerCase().includes(q.toLowerCase());
  //       }
  //       return String(row[key]).toLowerCase().includes(q.toLowerCase());
  //     });
  //   })
  //   return tableDataNew
  // }, [tableData, q]);


  const data = useMemo(() => {
    const tableDataNew = tableData.filter((row)=> {

      return columnNameAccessors.some((key) => {
        if (excludeColumnSearch.includes(key)) {
          return false;
        }
        if(key.split('.').length > 1){
          let keys = key.split('.');
          let newKey = row[keys[0]][keys[1]];
          return String(newKey).toLowerCase().includes(q.toLowerCase());
        }
        return String(row[key]).toLowerCase().includes(q.toLowerCase());
      });
    })
    return tableDataNew
  }, [tableData, q]);

  let component = <></>;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: cPageSize,
        pageIndex,
        sortBy: cSortBy
          ? [
            {
              id: cSortBy,
              desc,
            },
          ]
          : [],
      },
      disableMultiSort: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount,
    },
    useSortBy,
    usePagination,
    selectRow ? useRowSelect : "",
    selectRow
      ? (hooks) => {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              minWidth: 35,
              width: 35,
              maxWidth: 35,
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <Checkbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        });
      }
      : ""
  );

  if (tableData.length <= 0) {
    component = <p className="ml-4">Oops, no data found.</p>;
  } else {
    component = (
      <Table
        {...tableInstance}
        setPageIndex={setPageIndex}
        cSetPageSize={cSetPageSize}
        cSetSortBy={cSetSortBy}
        setDesc={setDesc}
        q={q}
        setSelectedRows={setSelectedRows}
        setQ={setQ}
      />
    );
  }
  return <>{component}</>;
};

export default TableInstance;
