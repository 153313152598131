import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_SOIL_PIT_MODAL, EDIT_SOIL_PIT_MODAL } from "../../types/constants";
import { FaPen, FaEye } from "react-icons/fa";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

interface UserProps {
  otherUrl?: string;
}

const SoilPit = ({ otherUrl }: UserProps) => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Soil Pit Id",
      accessor: "soil_pit_id",
    },
    {
      Header: "Pit Name",
      accessor: "pit_name",
    },
    {
      Header: "Pit Length",
      accessor: "pit_length",
    },
    {
      Header: "Pit Width",
      accessor: "pit_width",
    },
    {
      Header: "Pit Height",
      accessor: "pit_height",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Pit Photo",
      accessor: "pit_photo",
      Cell: ({ value }: any) => {
        return value ? (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-green-600 text-white px-2 py-3">
              <FaEye />
            </button>
          </a>
        ) : (
          <p className="text-red-500">No image</p>
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_SOIL_PIT_MODAL,
                  modalTitle: "Edit Soil Pit",
                  modalProps: {
                    soilPitId: row?.original.soil_pit_id,
                    pitName: row?.original.pit_name,
                    pitLength: row?.original.pit_length,
                    pitWidth: row?.original.pit_width,
                    pitHeight: row?.original.pit_height,
                    address: row?.original.address,
                    pitPhoto: row?.original.pit_photo,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [, setSelectedRows] = useState([]);

  let component = null;

  const getAllSoilPitDetails = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(otherUrl || `/soil_pit/all`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    ["getAllSoilPitDetails", pageIndex, cPageSize, cSortBy, desc, q],
    getAllSoilPitDetails
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_SOIL_PIT_MODAL,
                  modalTitle: "Add Soil Pit",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default SoilPit;
