import { AxiosError } from "axios";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../axiosInstance";
import { hideModal } from "../redux/features/modalSlice";
import { addToast } from "../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../types/constants";

interface AssignFPOObj {
    fpo_id: string;
    sp_id: string;
    quantity: number;
}

const useAssignProductToFPO = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const handleFPOAssign = useCallback(
        async (obj: AssignFPOObj) => {
            try {
                axiosInstance
                    .post(`/suncarbon_product/assign`, obj)
                    .then((response) => {
                        dispatch(
                            addToast({
                                kind: SUCCESS,
                                msg: `Product Assigned to FPO Successfully!`,
                            })
                        );
                        queryClient.invalidateQueries(["getProductFormulation"]);
                        queryClient.invalidateQueries(["getProductTransfer"]);
                        dispatch(hideModal());
                    })
                    .catch((error: AxiosError) => {
                        if (error.response) {
                            const { msg } = error.response.data as {
                                msg: string;
                            };
                            switch (error.response.status) {
                                case 400:
                                case 403:
                                case 500:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: msg,
                                        })
                                    );
                                    break;
                                case 404:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "No data found for the given FPO.",
                                        })
                                    );
                                    break;
                                default:
                                    console.log(error);
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Oops, something went wrong",
                                        })
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            console.log(error);
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                        } else {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`,
                                })
                            );
                        }
                    });
            } catch (error) {
                console.log(error);
                return;
            }
        },
        [dispatch, queryClient]
    );
    return {
        handleFPOAssign,
    };
};

export default useAssignProductToFPO;
