import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";

// interface FPOObj {
//   fpo_id: string;
//   fpo_name: string;
//   fpo_number: string;
//   fpo_email: string;
//   cin: string;
//   gst_number: string;
//   address: string;
//   is_active: boolean;
//   city: string;
//   state: string;
//   pincode: string;
//   enrolled_members: number;
//   project_type: string;
//   land_holding: number;
// }

type EditFPOModalProps = {
    fpo_id: string;
    fpo_name: string;
    fpo_number: string;
    fpo_email: string;
    cin: string;
    new_password: string;
    gst_number: string;
    address: string;
    is_active: boolean;
    city: string;
    state: string;
    pincode: string;
    enrolled_members: number;
    project_type: string;
    land_holding: number;
};

const EditFPOModal: React.FC<EditFPOModalProps> = ({
    fpo_id,
    fpo_name,
    fpo_number,
    fpo_email,
    cin,
    gst_number,
    address,
    is_active,
    city,
    state,
    pincode,
    enrolled_members,
    project_type,
    land_holding,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: EditFPOModalProps = {
        fpo_name: fpo_name,
        fpo_number: fpo_number,
        fpo_id: fpo_id,
        fpo_email: fpo_email,
        cin: cin,
        new_password: "",
        gst_number: gst_number,
        address: address,
        is_active: is_active,
        city: city,
        state: state,
        pincode: pincode,
        enrolled_members: enrolled_members,
        project_type: project_type,
        land_holding: land_holding,
    };

    const validationSchema = Yup.object({
        fpo_name: Yup.string().required("Name is Required"),
        fpo_number: Yup.number()
            .required("Number is Required")
            .min(1000000000, "Number must be at least 10 digits")
            .max(9999999999, "Number must be at most 10 digits"),
        fpo_email: Yup.string().email().required("Email is Required"),
        new_password: Yup.string()
            .optional()
            .min(8, "Password must be at least 8 characters"),
        cin: Yup.string().required("CIN is Required"),
        gst_number: Yup.string().required("GST Number is Required"),
        address: Yup.string()
            .optional()
            .min(2, "Address must be at least 2 characters")
            .max(100, "Address must be at most 100 characters"),
        is_active: Yup.boolean().optional(),
        city: Yup.string()
            .optional()
            .min(2, "City must be at least 2 characters")
            .max(50, "City must be at most 50 characters"),
        state: Yup.string()
            .optional()
            .min(2, "State must be at least 2 characters")
            .max(50, "State must be at most 50 characters"),
        pincode: Yup.string()
            .optional()
            .min(6, "Pincode must be at least 6 digits")
            .max(6, "Pincode must be at most 6 digits"),
        enrolled_members: Yup.number()
            .optional()
            .min(0, "Must have at least 0 member"),
        project_type: Yup.string().optional(),
        land_holding: Yup.number()
            .optional()
            .min(0, "Land holding must be at least 0"),
    });

    const handleSubmit = useCallback(
        async (fpo: EditFPOModalProps) => {
            setIsSubmitting(true);
            const obj = {
                fpo_id: fpo_id,
                fpo_name: fpo.fpo_name,
                fpo_number: fpo.fpo_number,
                fpo_email: fpo.fpo_email,
                new_password: fpo.new_password || undefined,
                cin: fpo.cin,
                gst_number: fpo.gst_number,
                address: fpo.address,
                is_active: fpo.is_active,
                city: fpo.city,
                state: fpo.state,
                pincode: String(fpo.pincode),
                enrolled_members: fpo.enrolled_members,
                project_type: fpo.project_type,
                land_holding: fpo.land_holding,
            };
            if(!fpo.new_password || fpo.new_password === "") {
                delete obj?.new_password;
            }
            try {
                const result = await axiosInstance.patch(`/fpo/update`, obj, {
                    headers: { ContentType: "application/json" },
                });

                queryClient.invalidateQueries(["getAllFpos"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "FPO Updated Successfully!",
                    })
                );

                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;

                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                } else if (error.request) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [queryClient, dispatch, fpo_id]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ values, handleChange }) => (
                <Form
                    className="px-6 py-4 mt-2"
                    style={{
                        minWidth: "360px",
                        maxWidth: "760px",
                    }}
                >
                    <Input
                        label="Name"
                        id="fpo_name"
                        name="fpo_name"
                        type="text"
                    />

                    <Input
                        label="Contact"
                        id="fpo_number"
                        name="fpo_number"
                        type="text"
                    />

                    <Input
                        label="Email"
                        id="fpo_email"
                        name="fpo_email"
                        type="email"
                    />

                    <Input label="CIN" id="cin" name="cin" type="text" />

                    <Input
                        label="New Password"
                        id="new_password"
                        name="new_password"
                        type="password"
                    />

                    <Input
                        label="GST Number"
                        id="gst_number"
                        name="gst_number"
                        type="text"
                    />

                    <Input
                        label="Address"
                        id="address"
                        name="address"
                        type="text"
                    />

                    <Input label="City" id="city" name="city" type="text" />

                    <Input label="State" id="state" name="state" type="text" />

                    <Input
                        label="Pincode"
                        id="pincode"
                        name="pincode"
                        type="number"
                    />

                    <Input
                        label="Enrolled Members"
                        id="enrolled_members"
                        name="enrolled_members"
                        type="number"
                    />

                    <Select
                        label="Project Type"
                        name="project_type"
                        id="project_type"
                        options={[
                            {
                                label: "Pyrolysis Kiln",
                                value: "pyrolysis_kiln",
                            },
                            { label: "Soil Pit", value: "soil_pit" },
                        ]}
                        classes="w-full mb-3"
                    />

                    <Input
                        label="Land Holding"
                        id="land_holding"
                        name="land_holding"
                        type="number"
                    />

                    <div className="buttons flex items-center w-full justify-center my-4">
                        <SubmitBtn
                            text="Save"
                            isSubmitting={isSubmitting}
                            classes="text-sm"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EditFPOModal;
