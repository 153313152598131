import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, KYCType, SUCCESS } from "../../types/constants";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

const stateArray = [
    { value: "", label: "Select State" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
];

interface UserObj {
    name: string;
    phonenumber: string;
    email: string;
    user_address: string;

    city: string;
    state: string;
    pincode: string;
    lat: string;
    lng: string;

    relationshipManager: string;
    company_email: string;
    company_mobile: string;
    site_address: string;
    site_lat: string;
    site_lng: string;
    artisan_kyc_doc_type: string;
    company_registration_id: string;
    fpo_id?: string;
}

const AddArtisanModal: React.FC = () => {
    const [kycDoc, setKycDoc] = useState<any>();
    const [siteImage, setSiteImage] = useState<any>();
    const [role, setRole] = useState(localStorage.getItem("role"));

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const [showForm, setShowForm] = useState(true);
    const queryClient = useQueryClient();
    const initialValues: UserObj = {
        name: "",
        phonenumber: "",
        email: "",
        user_address: "",

        city: "",
        state: "",
        pincode: "",
        lat: "0",
        lng: "0",

        relationshipManager: "",
        company_email: "",
        company_mobile: "",

        site_address: "",
        site_lat: "0",
        site_lng: "0",
        artisan_kyc_doc_type: "",
        company_registration_id: "",
        fpo_id: "",
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        phonenumber: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
            .length(10, "Phone Number must be exactly 10 digits")
            .required("Phone Number is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        user_address: Yup.string().required("User Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. pincode must be only digits")
            .length(6, "Pincode must be exactly 6 digits")
            .required("Pincode is required"),
        lat: Yup.string().required("Latitude is required"),
        lng: Yup.string().required("Longitude is required"),
        relationshipManager: Yup.string().required(
            "Relationship Manager is required"
        ),
        company_email: Yup.string()
            .email("Invalid email address")
            .required("Company Email is required"),
        company_mobile: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
            .length(10, "Phone Number must be exactly 10 digits")
            .required("Company Mobile is required"),

        site_address: Yup.string().required("Site Address is required"),
        site_lat: Yup.string().required("Site Latitude is required"),
        site_lng: Yup.string().required("Site Longitude is required"),

        artisan_kyc_doc_type: Yup.string().required(
            "KYC Document Type is required"
        ),
        company_registration_id: Yup.string().required(
            "Company Registration ID is required"
        ),
        fpo_id: Yup.string()
            .min(12, "FPO ID must be minimum of length 12")
            .optional(),
    });

    const handleSubmit = useCallback(
        async (
            values: UserObj,
            {
                setSubmitting,
            }: { setSubmitting: (isSubmitting: boolean) => void }
        ) => {
            setIsSubmitting(true);
            setCommonError("");

            try {
                const kycImageUrl = await uploadImage(kycDoc, AZURE_STORAGE_DIRECTORY.ARTISAN, AZURE_STORAGE_SUBDIRECTORY.KYC);
                const siteImageUrl = await uploadImage(siteImage, AZURE_STORAGE_DIRECTORY.ARTISAN, AZURE_STORAGE_SUBDIRECTORY.SITE);
                const obj = {
                    name: values.name,
                    phonenumber: values.phonenumber.toString(),
                    email: values.email,
                    user_address: {
                        address: values.user_address,
                        city: values.city,
                        state: values.state,
                        pincode: values.pincode.toString(),
                        lat: values.lat.toString(),
                        lng: values.lng.toString(),
                    },
                    relationshipManager: values.relationshipManager,
                    company_email: values.company_email,
                    company_mobile: values.company_mobile.toString(),
                    artisan_kyc_doc_url: kycImageUrl?.data?.publicUrl,
                    site_image_url: siteImageUrl?.data?.publicUrl,
                    site_address: {
                        address: values.site_address,
                        lat: values.site_lat.toString(),
                        lng: values.site_lng.toString(),
                    },
                    artisan_kyc_doc_type: values.artisan_kyc_doc_type,
                    company_registration_id: values.company_registration_id,
                    fpo_id: values?.fpo_id,
                };
                if (!values?.fpo_id) {
                    delete obj.fpo_id;
                }

                axiosInstance
                    .post(`/artisan/add`, obj, {
                        headers: {
                            ContentType: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsSubmitting(false);
                            setSubmitting(false);
                            dispatch(
                                addToast({
                                    kind: SUCCESS,
                                    msg: "Artisan Added Successfully!",
                                })
                            );
                            queryClient.invalidateQueries(["getArtisans"]);
                            setShowForm(false);
                            dispatch(hideModal());
                        }
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        setSubmitting(false);

                        if (error.response) {
                            const { msg } = error.response.data as {
                                msg: string;
                            };
                            switch (error.response.status) {
                                case 400:
                                case 403:
                                case 500:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `Error: ${msg}`,
                                        })
                                    )
                                    break;
                                case 404:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "No data found for the given Query.",
                                        })
                                    )
                                    break;
                                default:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Oops, something went wrong"
                                        })
                                    )
                                    setCommonError(
                                        "Oops, something went wrong"
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong"
                                })
                            )
                        } else {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`
                                })
                            )
                        }
                    });
            } catch (error) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong"
                    })
                )
                setIsSubmitting(false);
                setSubmitting(false);
                return;
            }
        },
        [dispatch, queryClient, kycDoc, siteImage]
    );

    return (
        <>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form
                            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                            style={{ minWidth: "50vw" }}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                height={100}
                                width={100}
                            />

                            <Input
                                label="Name"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                                className="w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Phone Number"
                                id="phonenumber"
                                name="phonenumber"
                                type="number"
                                placeholder="91 xxxxx xxxxx"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="example@gmail.com"
                            />
                            <Input
                                label="Relationship Manager"
                                id="relationshipManager"
                                name="relationshipManager"
                                type="text"
                                placeholder="Relationship Manager"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Company Email"
                                id="company_email"
                                name="company_email"
                                type="email"
                                placeholder="example@gmail.com"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Company Mobile"
                                id="company_mobile"
                                name="company_mobile"
                                type="number"
                                placeholder="91 xxxxx xxxxx"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <div className="mb-0 w-full">
                                <label className="block text-base font-semibold text-gray-800 text-center">
                                    User Address
                                </label>
                                <Input
                                    label="User Address"
                                    id="user_address"
                                    name="user_address"
                                    type="text"
                                    placeholder="User Address"
                                    className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                                <div className="flex mb-4 w-full space-x-4">
                                    <Input
                                        label="City"
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <div className="w-full">
                                        <Select
                                            options={stateArray}
                                            label="State"
                                            id="state"
                                            name="state"
                                        />
                                    </div>
                                    <Input
                                        label="Pin Code"
                                        id="pincode"
                                        name="pincode"
                                        type="text"
                                        placeholder="Pin Code"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                </div>

                                <div className="flex w-full space-x-4">
                                    <Input
                                        label="Latitude"
                                        id="lat"
                                        name="lat"
                                        type="text"
                                        placeholder="eg. 12.1457"
                                        readOnly
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />

                                    <Input
                                        label="Longitude"
                                        id="lng"
                                        name="lng"
                                        type="text"
                                        readOnly
                                        placeholder="eg. 17.5984"
                                        className="w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                </div>
                                <CenterMarker latName="lat" lngName="lng" />
                            </div>

                            <div className="w-full mt-5 mb-5">
                                <Select
                                label="Artisan KYC Doc Type"
                                id="artisan_kyc_doc_type"
                                name="artisan_kyc_doc_type"
                                placeholder="eg. aadhar"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                options={KYCType}
                            />
                            </div>
                            
                            <Input
                                label="Artisan KYC Doc"
                                id="artisan_kyc_doc_url"
                                name="artisan_kyc_doc_url"
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                    if (e.target.files)
                                        setKycDoc(e.target?.files[0]);
                                }}
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <Input
                                label="Site Image"
                                id="site_image_url"
                                name="site_image_url"
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                    if (e.target.files)
                                        setSiteImage(e.target?.files[0]);
                                }}
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <div className="mb-5 w-full">
                                <label className="block text-base font-semibold text-gray-800 text-center">
                                    Site Address
                                </label>
                                <Input
                                    label="Site Address"
                                    id="site_address"
                                    name="site_address"
                                    type="text"
                                    placeholder="Site Address"
                                    className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                                <div className="flex w-full space-x-4">
                                    <Input
                                        label="Site Latitude"
                                        id="site_lat"
                                        name="site_lat"
                                        type="text"
                                        readOnly
                                        placeholder="eg. 12.1457"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    {/* <Input
                                        label="Site Longitude"
                                        id="site_lng"
                                        name="site_lng"
                                        type="text"
                                        readonly
                                        placeholder="eg. 17.5984"
                                        className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    /> */}

                                    <Input
                                        label="Site Longitude"
                                        id="site_lng"
                                        name="site_lng"
                                        type="text"
                                        readOnly
                                        placeholder="eg. 17.5984"
                                        className="w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                </div>
                                <CenterMarker latName="site_lat" lngName="site_lng" />
                            </div>

                            <Input
                                label="Company Registration ID"
                                id="company_registration_id"
                                name="company_registration_id"
                                type="text"
                                placeholder="XXX-XXX-XXX-XXX"
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            {role === "admin" && (
                                <Input
                                    label="FPO ID"
                                    id="fpo_id"
                                    name="fpo_id"
                                    type="text"
                                    placeholder="SUN-FPO-XXXXXXXXXX"
                                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                            )}

                            {commonError && (
                                <div className="common-error mb-2 text-center">
                                    <ErrorBox msg={commonError} />
                                </div>
                            )}

                            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                                <SubmitBtn
                                    text="Add Artisan"
                                    isSubmitting={isSubmitting}
                                    classes="text-sm"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default AddArtisanModal;
