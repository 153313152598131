import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import { uploadImage } from "../../http/uploadImage";

interface BiomassConfigObj {
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
}

type EditBiomassConfigModalProps = {
  biomassConfigId: string;
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
  addressId: string;
};

const EditBiomassConfigModal: React.FC<EditBiomassConfigModalProps> = ({
  biomassConfigId,
  biomassName,
  avgBiomassDia,
  avgBiomassLen,
  biomassPhoto,
  cottonStock,
  riceStraw,
  test,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImg, setNewImg] = useState<File | null>(null);

  const initialValues: BiomassConfigObj = {
    biomassName,
    avgBiomassDia,
    avgBiomassLen,
    biomassPhoto,
    cottonStock,
    riceStraw,
    test,
  };

  const validationSchema = Yup.object({
    biomassName: Yup.string().required("Biomass Name is Required"),
    avgBiomassDia: Yup.number().required("Average Biomass Diameter is Required"),
    avgBiomassLen: Yup.number().required("Average Biomass Length is Required"),
    biomassPhoto: Yup.string().required("Biomass Image is Required"),
    cottonStock: Yup.number().required("Cotton Stock is Required"),
    riceStraw: Yup.number().required("Rice Straw is Required"),
    test: Yup.string(),
  });

  const handleSubmit = useCallback(
    async (biomass_config: BiomassConfigObj) => {
      setIsSubmitting(true);
      try {
        let finalPhotoUrl = biomassPhoto;
        if (newImg) {
          const imgRes = await uploadImage(newImg, AZURE_STORAGE_DIRECTORY.BIOMASS_COMPOSITION, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
          if (imgRes.data.publicUrl) {
            finalPhotoUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }

        const result = await axiosInstance.patch(
          `/biomass_composition/${biomassConfigId}`,
          {
            biomass_name: biomass_config.biomassName,
            average_biomass_diameter: biomass_config.avgBiomassDia,
            average_biomass_length: biomass_config.avgBiomassLen,
            biomass_photo: finalPhotoUrl,
            biomass_blend: {
              cotton_stock: biomass_config.cottonStock,
              rice_straw: biomass_config.riceStraw,
              test: biomass_config.test,
            },
            addressId,
          },
          { headers: { "Content-Type": "application/json" } }
        )

        queryClient.invalidateQueries(["getAllBiomassComposition"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass Config Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [biomassConfigId, addressId, queryClient, dispatch, newImg, biomassPhoto]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <Input
            label="Biomass Name"
            id="biomassName"
            name="biomassName"
            type="text"
          />

          <h2 className="text-xl font-semibold">Biomass Blend Details</h2>

          <Input
            label="Cotton Stock"
            id="cottonStock"
            name="cottonStock"
            type="number"
            value={values.cottonStock}
            onChange={handleChange}
          />

          <Input
            label="Rice Straw"
            id="riceStraw"
            name="riceStraw"
            type="number"
            value={values.riceStraw}
            onChange={handleChange}
          />

          <Input
            label="Test"
            id="test"
            name="test"
            type="text"
            value={values.test}
            onChange={handleChange}
          />

          <Input
            label="Average Biomass Diameter"
            id="avgBiomassDia"
            name="avgBiomassDia"
            type="number"
          />

          <Input
            label="Average Biomass Length"
            id="avgBiomassLen"
            name="avgBiomassLen"
            type="number"
          />

          <div className="mt-4">
            <label htmlFor="biomass_photo_preview"
              className="block text-sm font-bold text-gray-700">
              Biomass Photo Preview
            </label>
            <ImagePreview
              imageUrl={biomassPhoto}
              altText="Biomass Photo Preview" />
          </div>

          <div className="mt-4">
            <Input
              label="Upload Biomass Photo"
              id="new_biomass_photo"
              name="new_biomass_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.webp"
              onChange={(e) => {
                if (e.target.files) {
                  setNewImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              classes="text-sm"
            // checkDirty={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditBiomassConfigModal;
