import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import { uploadImage } from "../../http/uploadImage";

interface PyrolysisKlinObj {
  depth: number;
  kiln_photo: string;
  kiln_name: string;
  kiln_type: string;
  upper_diameter: number;
  lower_diameter: number;
}

type EditPyrolysisKilnModalProps = {
  pyrolysisKilnId: string;
  depth: number;
  kiln_photo: string;
  kiln_name: string;
  kiln_type: string;
  upper_diameter: number;
  lower_diameter: number;
};

const EditPyrolysisKilnModal: React.FC<EditPyrolysisKilnModalProps> = ({
  pyrolysisKilnId,
  depth,
  kiln_photo,
  kiln_name,
  kiln_type,
  upper_diameter,
  lower_diameter,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImg, setNewImg] = useState<File | null>(null);
  const initialValues: PyrolysisKlinObj = {
    depth,
    kiln_photo,
    kiln_name,
    kiln_type,
    upper_diameter,
    lower_diameter,
  };

  const validationSchema = Yup.object({
    depth: Yup.number().required("Depth is required"),
    kiln_photo: Yup.string().required("Kiln Photo is required"),
    kiln_name: Yup.string().required("Kiln Name is required"),
    kiln_type: Yup.string().required("Kiln Type is required"),
    upper_diameter: Yup.number().required("Upper Diameter is required"),
    lower_diameter: Yup.number().required("Lower Diameter is required"),
  });

  const handleSubmit = useCallback(
    async (pyrolysisKiln: PyrolysisKlinObj) => {
      setIsSubmitting(true);
      try {
        let finalPhotoUrl = kiln_photo;
        if (newImg) {
          const imgRes = await uploadImage(newImg, AZURE_STORAGE_DIRECTORY.PYROLYSIS_KILN, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
          if (imgRes.data.publicUrl) {
            finalPhotoUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }
        const result = await axiosInstance.patch(
          `/pyrolysis_kiln/update/${pyrolysisKilnId}`,
          {
            depth: pyrolysisKiln.depth,
            kiln_photo: finalPhotoUrl,
            kiln_name: pyrolysisKiln.kiln_name,
            kiln_type: pyrolysisKiln.kiln_type,
            upper_diameter: pyrolysisKiln.upper_diameter,
            lower_diameter: pyrolysisKiln.lower_diameter,
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Pyrolysis Kiln Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, queryClient, pyrolysisKilnId, newImg, kiln_photo]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        {/* <div className="flex item-center gap-x-6"> */}
          <Input label="Depth"
            id="depth"
            name="depth"
            type="number" />

          <Input label="Kiln Name"
            id="kiln_name"
            name="kiln_name"
            type="text" />
        {/* </div> */}

        {/* <div className="flex item-center gap-x-6"> */}
          <Input label="Kiln Type"
            id="kiln_type"
            name="kiln_type"
            type="text" />

          <Input
            label="Upper Diameter"
            id="upper_diameter"
            name="upper_diameter"
            type="number"
          />
        {/* </div> */}

        {/* <div className="flex item-center gap-x-6"> */}
          <Input
            label="Lower Diameter"
            id="lower_diameter"
            name="lower_diameter"
            type="number"
          />
        {/* </div> */}

        <div className="mt-4">
          <label htmlFor="kiln_photo"
            className="block text-sm font-extrabold text-black">
            Kiln Photo
          </label>
          <ImagePreview
            imageUrl={kiln_photo}
            altText="Kiln Photo Preview" />
        </div>

        <div className="mt-4">
          <Input
            label="Upload Kiln Photo"
            id="new_kiln_photo"
            name="new_kiln_photo"
            type="file"
            accept=".jpg,.png,.jpeg,.webp"
            onChange={(e) => {
              if (e.target.files) {
                setNewImg(e.target.files[0]);
              }
            }}
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditPyrolysisKilnModal;
