import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface BuyerObj {
    name: string;
    relationshipManager: string | null;
    email: string;
}

type EditBuyerModalProps = {
    user_id: string;
    name: string;
    relationshipManager: string | null;
    email: string;
};

const EditBuyerModal: React.FC<EditBuyerModalProps> = ({
    user_id,
    name,
    relationshipManager,
    email,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues: BuyerObj = {
        name: name,
        relationshipManager: relationshipManager || null,
        email: email,
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is Required"),
        relationshipManager: Yup.string().nullable(),
        email: Yup.string().required("Email is Required"),
    });

    const handleSubmit = useCallback(
        async (buyer: BuyerObj) => {
            setIsSubmitting(true);
            try {
                const result = await axiosInstance.patch(
                    `/admin/users/update/${user_id}`,
                    {
                        name: buyer.name,
                        relationshipManager: buyer.relationshipManager,
                        email: buyer.email,
                    },
                    { headers: { ContentType: "application/json" } }
                );

                queryClient.invalidateQueries(["getBuyers"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Buyer Updated Successfully!",
                    })
                );
                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;
                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                } else if (error.request) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [dispatch, queryClient, user_id]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form
                className="px-6 py-4 mt-2"
                style={{
                    minWidth: "360px",
                    maxWidth: "760px",
                }}
            >
                <div className="flex item-center gap-x-6">
                    <Input label="Name"
                        id="name"
                        name="name"
                        type="text" />
                </div>

                <div className="flex item-center gap-x-6">
                    <Input
                        label="Relationship Manager"
                        id="relationshipManager"
                        name="relationshipManager"
                        type="text"
                    />
                </div>

                <div className="flex item-center gap-x-6">
                    <Input
                        label="Email"
                        id="email"
                        name="email"
                        type="text"
                    />
                </div>

                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Save"
                        isSubmitting={isSubmitting}
                        classes="text-sm"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default EditBuyerModal;
