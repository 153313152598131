import { PreparationData } from "../../types";

interface ViewPreparationModalProps {
  data: PreparationData;
}

const ViewPreparationModal = (props: ViewPreparationModalProps) => {
  const {
    artisan_name,
    avg_moisture_percentage,
    biomass_moistures,
    biomass_name,
    biomass_photo,
    biomass_quantity,
    kiln_name,
    kiln_photo,
    updatedAt,
    biomass_preparation_photo,
  } = props.data;

  return (
    <div
      className="px-6 py-4 mt-2"
      style={{
        minWidth: "360px",
        maxWidth: "760px",
      }}
    >
      <div className="flex flex-row item-center gap-x-6">
        <div className="text-xl flex flex-col gap-4">
          <p>Artisan Name : {artisan_name}</p>
          <p>Biomass Name : {biomass_name}</p>
          <p>Biomass Quantity : {biomass_quantity}</p>
          <p>Moisture Percentage : {avg_moisture_percentage}</p>
          <p>
            Biomass Photo:{" "}
            <a
              href={biomass_preparation_photo}
              className="text-green-400"
              target="_blank"
              rel="noreferrer"
            >
              Open Image
            </a>
          </p>

          <p className="flex gap-4">
            Biomass Moistures :{" "}
            {biomass_moistures.map((e) => (
              <span>{e + ","}</span>
            ))}
          </p>

          <p>Kiln Name : {kiln_name}</p>

          <p>
            Kiln Photo:{" "}
            <a
              href={kiln_photo}
              className="text-green-400"
              target="_blank"
              rel="noreferrer"
            >
              Open Image
            </a>
          </p>

          <p>Updated At : {updatedAt.slice(0, 10)}</p>

        </div>
        
      </div>
    </div>
  );
};

export default ViewPreparationModal;
