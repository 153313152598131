import axios from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import ErrorBox from "../../components/FormikComponents/ErrorBox";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import { loginUser } from "../../redux/features/authSlice";

interface UserObj {
  identifier: string;
  password: string;
}

interface Payload {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [commonError, setCommonError] = useState("");
  const initialValues: UserObj = { identifier: "", password: "" };

  const validationSchema = Yup.object({
    identifier: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = useCallback(
    async (values: UserObj, { setSubmitting }: FormikHelpers<UserObj>) => {

      setSubmitting(true);
      setCommonError("");

      let url: string;
      let payload: Payload;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(values.identifier)) {
        url = "/users/auth/login/password";
        payload = { username: values.identifier, password: values.password };
      } else {
        url = "/fpo/auth/login";
        payload = { username: values.identifier, password: values.password };
      }

      try { 
        const response = await axiosInstance.post(url, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = response.data.data;

        dispatch(
          loginUser({
            accessToken: data.token,
            refreshToken: "",
            role: data.role || "fpo",
          })
        );
      } catch (error) {
        console.error("Login error:", error);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const { status, data } = error.response;
            switch (status) {
              case 401:
                setCommonError(data?.msg || "Invalid credentials");
                break;
              case 400:
              case 403:
              case 500:
                setCommonError(data.msg || "An error occurred during login");
                break;
              case 404:
                setCommonError("No user found with the given credentials.");
                break;
              default:
                setCommonError("Oops, something went wrong");
                break;
            }
          } else if (error.request) {
            setCommonError("No response received from the server");
          } else {
            setCommonError(`Error: ${error.message}`);
          }
        } else {
          setCommonError("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch]
  );

  return (
    <div
      className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
      style={{ minWidth: "360px" }}
    >
      <img src={logo} alt="logo" height={100} width={100} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <Input
              label="Username"
              id="identifier"
              name="identifier"
              type="text"
            />
            <Input
              label="Password"
              id="password"
              name="password"
              type="password"
            />

            {commonError && (
              <div className="common-error mb-2 text-center">
                <ErrorBox msg={commonError} />
              </div>
            )}

            <SubmitBtn
              text="Login"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
