import axiosInstance from "../axiosInstance";

export const uploadImage = async (file: object, directory: string, subdirectory:string) => {

  const formData = new FormData();
  formData.append("image", file as Blob);
  formData.append("directory", directory as string);
  formData.append("subdirectory", subdirectory as string);

  const result = await axiosInstance.post(`/users/upload/image`, formData);
  return result.data;
};
