import { useSelector } from "react-redux";
import { RootState } from "../redux/app";
import { USER_ROLES } from "../types/constants";
import AdminLayout from "./AdminLayout";


const MainLayout = () => {
  const { user } = useSelector((state: RootState) => state.auth);


  if (user) {
    if (user.role === USER_ROLES.ADMIN) {
      return <AdminLayout />;
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {"Loading..."}
    </div>
  );
};

export default MainLayout;
