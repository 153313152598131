import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { addToast } from "../../redux/features/toastSlice";
import { SUCCESS } from "../../types/constants";
import Loader from "../Loader/Loader";

interface ViewArtisanorProjectModalProps {
  isArtisanList?: boolean;
  id: string;
  isProjectsList?: boolean;
}

const ViewArtisanOrProjectModal = (props: ViewArtisanorProjectModalProps) => {
  const { isArtisanList, id, isProjectsList } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [artisansList, setArtisansList] = useState<any | null>(null);
  const [projectsList, setProjectsList] = useState<any | null>(null);

  useEffect(() => {
    const getAllArtisansForProject = async () => {
      setIsLoading(true);
      try {
        const result = await axiosInstance.get("/project/artisans/" + id);

        setArtisansList(result.data.data.artisans);
      } catch (error: any) {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Error Fetching Artisans List",
          })
        );
      }
      setIsLoading(false);
    };

    const getAllProjectsForArtisan = async () => {
      setIsLoading(true);
      try {
        const result = await axiosInstance.get("/project/projects/" + id);

        setProjectsList(result.data.data.projects);
      } catch (error: any) {
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Error Fetching Artisans List",
          })
        );
      }
      setIsLoading(false);
    };

    if (isArtisanList && isArtisanList === true) {
      getAllArtisansForProject();
    }
    if (isProjectsList && isProjectsList === true) {
      getAllProjectsForArtisan();
    }
  }, [isArtisanList, id, isProjectsList]);

  if (isLoading) return <Loader />;

  return (
    <div
      className="px-6 py-4 mt-2"
      style={{
        minWidth: "360px",
        maxWidth: "760px",
      }}
    >
      <div className="flex flex-row item-center gap-x-6">
        <div className="text-xl flex flex-col gap-4 max-h-96 overflow-x-scroll">
          {artisansList && artisansList.length > 0 ? (
            artisansList.map((artisan: any) => {
              return (
                <div
                  key={artisan.id}
                  className="flex flex-col gap-2 border border-gray-200 p-4 rounded-md"
                >
                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Name:</div>
                    <div>{artisan.name}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Email:</div>
                    <div>{artisan.artisan_email}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Address:</div>
                    <div>{artisan.address}</div>
                  </div>

                </div>
              );
            })
          ) : (
            <div>{!projectsList && <p>No Artisans assigned</p>}</div>
          )}
          {projectsList && projectsList.length > 0 ? (
            projectsList.map((project: any) => {
              return (
                <div
                  key={project.id}
                  className="flex flex-col gap-2 border border-gray-200 p-4 rounded-md"
                >
                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Project Name:</div>
                    <div>{project.project_name}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Person Name:</div>
                    <div>{project.project_contact_details.person_name}</div>
                  </div>

                  <div className="flex flex-row gap-2">
                    <div className="font-semibold">Contact:</div>
                    <div>{project.project_contact_details.person_phone}</div>
                  </div>

                </div>
              );
            })
          ) : (
            <div>{!isArtisanList && <p>No Projects assigned</p>}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewArtisanOrProjectModal;
