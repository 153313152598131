import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import TextArea from "../FormikComponents/TextArea";

interface NoteObj {
    comment: string;
    seller_id: string;
    biomass_type: string | null;
    vehicle_type?: string | null;
    vehicle_number?: string | null;
    biomass_quantity?: string | null;
    biomass_price?: number | null;
    // Define other properties explicitly if needed
    [key: string]: any;
}

interface UserProps {
    name: string;
    sellerId: string;
    notes: NoteObj[];
};

const AddNoteModal: React.FC<UserProps> = ({
    sellerId,
    name,
    notes
}) => {

    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: NoteObj = {
        comment: "",
        seller_id: sellerId,
        biomass_type: "",
        vehicle_type: "",
        vehicle_number: "",
        biomass_quantity: "",
        biomass_price: 0
    };

    const validationSchema = Yup.object({
        comment: Yup.string().min(15, "Comment must be atleast 15 character long.").max(250, "Comment must be less than 250 characters.").required("Comment is Required"),
        seller_id: Yup.string().required("Seller ID is Required"),
        biomass_type: Yup.string().required("Biomass Type is Required"),
        biomass_quantity: Yup.number().min(1, "Quantity must be atleast 1").required("Biomass Quantity is Required"),
        biomass_price: Yup.number().min(1, "Price must be greater than 0.").required("Biomass Price is Required"),
    });

    const handleSubmit = useCallback(
        async (note: NoteObj) => {
            setIsSubmitting(true);
            try {

                let noteObj: NoteObj = {
                    comment: note.comment,
                    seller_id: note.seller_id,
                    biomass_type: note.biomass_type,
                    biomass_quantity: note.biomass_quantity?.toString(),
                    biomass_price: note.biomass_price
                }
                if (note.vehicle_type != "") {
                    noteObj.vehicle_type = note.vehicle_type;
                } if (note.vehicle_number != "") {
                    noteObj.vehicle_number = note.vehicle_number;
                }

                const result = await axiosInstance.post(
                    `/admin/note/create`,
                    noteObj,
                    { headers: { ContentType: "application/json" } }
                );


                queryClient.invalidateQueries(["getSellers"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: "Note Added Successfully!",
                    })
                );

                dispatch(hideModal());
            } catch (error: any) {
                if (error.response) {
                    const response = error.response;
                    const { msg } = response.data;

                    switch (response.status) {
                        case 400:
                        case 500:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: msg,
                                })
                            );
                            break;
                        default:
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            );
                            break;
                    }
                } else if (error.request) {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: "Oops, something went wrong",
                        })
                    );
                } else {
                    dispatch(
                        addToast({
                            kind: ERROR,
                            msg: `Error: ${error.message}`,
                        })
                    );
                }
            }
            setIsSubmitting(false);
        },
        [dispatch, queryClient, sellerId]
    );

    return (
        <div>
            <div id="notes" className="max-h-72 p-7 m-4 overflow-auto">
                {notes[0] ? notes.map((note, index) => (

                    <div className="grid grid-cols-3 gap-10 mb-12" key={index}>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Biomass Type: </h3>
                            <p className="text-base font-medium text-green-500">{note.biomass_type}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Vehicle Number: </h3>
                            <p className="text-base font-medium text-green-500">{note.vehicle_number ? note.vehicle_number : "NONE"}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Vehicle Type: </h3>
                            <p className="text-base font-medium text-green-500">{note.vehicle_type ? note.vehicle_type : "NONE"}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Biomass Quantity: </h3>
                            <p className="text-base font-medium text-green-500">{note.biomass_quantity}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Biomass Price: </h3>
                            <p className="text-base font-medium text-green-500">Rs. {note.biomass_price}</p>
                        </div>
                        <div className="flex items-start col-span-3">
                            <h3 className="text-base font-semibold mr-4">Comment:</h3>
                            <p className="text-base font-normal">{note.comment}</p>
                        </div>
                        <hr className="col-span-3" />
                    </div>
                )) :
                    ("Add a note to view here")}

            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                <Form
                    className="p-7 m-4 grid grid-cols-1 lg:grid-cols-2 gap-4 border border-green-400 rounded-lg"
                    style={{
                        minWidth: "50vw",
                        maxWidth: "90vw",
                    }}
                >

                    <SelectDropDownAsync
                        label="Biomass Type"
                        id="biomass_type"
                        name="biomass_type"
                        classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                        loadOptions={async (
                            inferString: string
                        ) => {
                            try {
                                const response =
                                    await axiosInstance.get(
                                        "/biomass_composition/all"
                                    );
                                let requiredData: any = [];
                                for (
                                    let i = 0;
                                    i <
                                    response.data.data.length;
                                    i++
                                ) {
                                    if (response.data.data[i].biomass_name.toLowerCase().replace(" ", "").includes(inferString.toLowerCase())) {
                                        requiredData.push({
                                            label: response.data
                                                .data[i]
                                                .biomass_name,
                                            value: response.data
                                                .data[i]
                                                .biomass_name,
                                        });
                                    }
                                }
                                return requiredData;
                            } catch (error) {
                                return [
                                    {
                                        label: "Error. Something went wrong!!",
                                        value: "error",
                                    },
                                ];
                            }
                        }}
                    />

                    <Select id="vehicle_type" name="vehicle_type" label="Vehicle Type" options={[
                        {
                            label: "Type 1",
                            value: "Type 1"
                        }
                    ]} />

                    <div className="col-span-1 lg:col-span-2">
                        <Input label="Vehicle Number" id="vehicle_number" name="vehicle_number" type="text" placeholder="Vehicle Number (Optional)" />

                    </div>

                    <Input
                        label="Biomass Quantity"
                        placeholder="Biomass Quantity"
                        id="biomass_quantity"
                        name="biomass_quantity"
                        type="number"
                    />
                    <Input
                        label="Biomass Price"
                        placeholder="Biomass Price"
                        id="biomass_price"
                        name="biomass_price"
                        type="number"
                    />

                    <TextArea
                        label="Comment"
                        id="comment"
                        name="comment"
                        classes="col-span-1 lg:col-span-2"
                    />

                    <button title="Cancel" onClick={() => dispatch(hideModal())} className="border border-gray-800 rounded">
                        Cancel
                    </button>
                    <SubmitBtn
                        text="Save"
                        isSubmitting={isSubmitting}
                        classes="text-sm"
                    />
                </Form>
            </Formik>
        </div>

    );
};

export default AddNoteModal;
