import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface AllFertilisersCombObj {
  name: string;
}

type EditAllFerilisersCombModalProps = {
  fertiliserCombId: string;
  name: string;
  addressId: string;
};

const EditAllFerilisersCombModal: React.FC<EditAllFerilisersCombModalProps> = ({
  fertiliserCombId,
  name,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: AllFertilisersCombObj = {
    name: name,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Fertiliser Name is Required"),
  });

  const handleSubmit = useCallback(
    async (all_fertilisers_comb: AllFertilisersCombObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/fertilisers/comb/${fertiliserCombId}`,
          {
            name: all_fertilisers_comb.name,
            addresses: [
              {
                addressId: addressId,
              },
            ],
          },
          { headers: { ContentType: "application/json" } }
        );
        

        queryClient.invalidateQueries(["getProducts"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Fertilisers Combos Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [fertiliserCombId, addressId, dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Fertiliser Name" id="name" name="name" type="text" />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditAllFerilisersCombModal;
