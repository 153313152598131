import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface CollapsibleProps {
  title: string;
  content?: JSX.Element;
  classes?: string;
}
function Collapsible({title, content, classes}: CollapsibleProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <section className={`my-6 ${classes}`}>
      <div className="bg-green-50 w-full rounded-xl border border-green-500">
        <button onClick={() => setIsCollapsed(!isCollapsed)} className="w-full text-left p-4 flex items-center justify-between">
          <h3 className="text-lg font-medium flex-1 ml-auto">{title}</h3>
          {isCollapsed ? <IoIosArrowDown/> : <IoIosArrowUp />}
        </button>
      </div>

      {!isCollapsed && (
        <div className="bg-gray-100 p-4">
          {content}
        </div>
      )}
    </section>
  )
}

export default Collapsible