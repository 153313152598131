import { useField } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import AsyncSelect from "react-select/async";
import ErrorBox from "./ErrorBox";

interface Props {
    label: string;
    id: string;
    name: string;
    loadOptions?: (val: string) => Promise<any>;
    components?: Object;
    isMulti?: boolean;
    autoFocus?: boolean;
    optional?: boolean;
    inline?: boolean;
    classes?: string;
    setLabelText?: Dispatch<SetStateAction<string>>;
}

const customStyles = {
    control: (provided: any, state: any) => {
        return {
            ...provided,
            borderWidth: 2,
            borderRadius: 8,
            padding: 4,
            boxShadow: "none",
            ":hover": {
                borderColor: "#28a84a",
                cursor: "text",
            },
            borderColor: state.isFocused ? "#28a84a" : "#44444433",
        };
    },
};

const SelectDropDownAsync = ({
    label,
    id,
    name,
    loadOptions,
    classes,
    components = {},
    isMulti = false,
    autoFocus = false,
    optional = false,
    inline = false,
    setLabelText,
}: Props) => {
    const [field, meta, helpers] = useField({ name });

    return (
        <div className={`flex flex-col w-full mb-6 ${classes}`}>
            <div
                className={`form-group flex ${
                    inline
                        ? " flex-row items-center"
                        : "flex-col justify-center"
                }`}
            >
                <label
                    className={`font-extrabold text-sm ${inline ? "mr-2 w-28" : "mb-2"
                        }`}
                    htmlFor={id}
                >
                    {label}{" "}
                    {optional && (
                        <span className="optional text-slate-400">
                            (Optional)
                        </span>
                    )}
                </label>

                <AsyncSelect
                    styles={customStyles}
                    className="text-sm"
                    isMulti={isMulti}
                    components={components}
                    defaultOptions
                    cacheOptions={true}
                    loadOptions={loadOptions}
                    onChange={(value: any) => {
                        isMulti
                            ? helpers.setValue(value)
                            : helpers.setValue(value.value);
                        if (setLabelText && !isMulti) setLabelText(value.label);
                        // helpers.setValue(value.value || value);
                    }}
                    value={field.value?.value}
                    // value={field.value?.value ? field.value : field.value.value}
                    autoFocus={autoFocus}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: "#28a84a",
                        },
                    })}
                />
            </div>
            {meta.touched && meta.error && <ErrorBox msg={meta.error} />}
        </div>
    );
};

export default SelectDropDownAsync;
