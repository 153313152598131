import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

const stateArray = [
    { value: "", label: "Select State" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
];

interface UserObj {
    name: string;
    phonenumber: string;
    email: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    gstin: string;
    productCategories: string;
    companyCategory: string;
    companyName: string;
    lat?: string;
    lng?: string;
    fpo_id?: string;

    company_address?: string;
    company_email?: string;
    company_mobile?: string;
    owner_kyc_doc_url?: string;
    addressProof?: string;
    onsite_person_name?: string;
    onsite_person_mobile?: string;
    site_image_url?: string;
    biomass_type?: string;
    site_address?: string;
    company_lat?: string;
    company_lng?: string;
}

const AddBuyerModal: React.FC = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const [showForm, setShowForm] = useState(true);
    const queryClient = useQueryClient();
    const [role, setRole] = useState(localStorage.getItem("role"));

    const initialValues: UserObj = {
        name: "",
        phonenumber: "",
        email: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        gstin: "",
        productCategories: "",
        companyCategory: "",
        companyName: "",
        lat: "0",
        lng: "0",
        fpo_id: "",

        // in case of biomass only
        company_address: "",
        company_email: "",
        company_mobile: "",
        onsite_person_name: "",
        onsite_person_mobile: "",
        biomass_type: "",
        site_address: "",
        company_lat: "0",
        company_lng: "0",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phonenumber: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
            .length(10, "Phone Number must be exactly 10 digits")
            .required("Phone Number is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Pin Code must be only digits")
            .length(6, "Pin Code must be exactly 6 digits")
            .required("Pin Code is required"),
        gstin: Yup.string()
            // .required("GST Number is required")
            .optional()
            .min(12, "GST Number must be 12 characters long")
            .max(15, "GST Number must be 15 characters long"),
        productCategories: Yup.string().required(
            "Product Categories are required"
        ),
        companyCategory: Yup.string().required("Company Category is required"),
        companyName: Yup.string().required("Company / Firm / Individual Name is required"),

        lat: Yup.string().nullable(),
        lng: Yup.string().nullable(),
    });

    const [files, setfiles] = useState<any>();
    const [kycDoc, setKycDoc] = useState<any>();
    const [siteImage, setSiteImage] = useState<any>();


    const handleSubmit = useCallback(
        async (
            values: UserObj,
            {
                setSubmitting,
            }: { setSubmitting: (isSubmitting: boolean) => void }
        ) => {
            // setIsSubmitting(true);
            setCommonError("");

            try {
                if (!values.lat || !values.lng) {
                    setCommonError("Please enter valid Latitude and Longitude");
                    return;
                }
                let obj = new FormData();
                obj.append("name", values.name);
                obj.append("phonenumber", values.phonenumber.toString());
                obj.append("email", values.email);
                obj.append("address", values.address);
                obj.append("city", values.city);
                obj.append("state", values.state);
                obj.append("pincode", values.pincode.toString());

                if (values.gstin !== "") {
                    obj.append("gstin", values.gstin);
                }
                obj.append("productCategories", values.productCategories);
                obj.append("companyCategory", values.companyCategory);
                obj.append("companyName", values.companyName);
                obj.append("lat", values.lat?.toString());
                obj.append("lng", values.lng?.toString());


                if (files) {
                    obj.append("addressProof", files);
                }


                if (values.fpo_id) {
                    obj.append("fpo_id", values.fpo_id);
                }
                if (values.productCategories.includes("biomass")) {
                    if (!kycDoc) {
                        setCommonError("Please upload KYC Document");
                        return;
                    }
                    if (!files) {
                        setCommonError("Please upload Address Proof Document");
                        return;
                    }
                    if (!siteImage) {
                        setCommonError("Please upload Site Image");
                        return;
                    }
                    if (!values.company_lat || !values.company_lng) {
                        setCommonError(
                            "Please enter valid Company Latitude and Longitude"
                        );
                        return;
                    }
                    if (!values.biomass_type) {
                        setCommonError("Please enter Biomass Type");
                        return;
                    }
                    if (!values.site_address) {
                        setCommonError("Please enter Site Address");
                        return;
                    }
                    if (!values.company_address) {
                        setCommonError("Please enter Company Address");
                        return;
                    }
                    if (!values.company_email) {
                        setCommonError("Please enter Company Email");
                        return;
                    }
                    if (!values.company_mobile) {
                        setCommonError("Please enter Company Mobile");
                        return;
                    }
                    if (
                        values?.company_mobile &&
                        (values?.company_mobile?.toString().length > 10 || values?.company_mobile?.toString().length < 10)
                    ) {
                        setCommonError("Please enter valid Company Mobile");
                        return;
                    }
                    if (!values.onsite_person_name) {
                        setCommonError("Please enter Onsite Person Name");
                        return;
                    }
                    if (
                        values.onsite_person_mobile &&
                        (values.onsite_person_mobile?.toString().length > 10 || values.onsite_person_mobile?.toString().length < 10)
                    ) {
                        setCommonError(
                            "Please enter valid Onsite Person Mobile"
                        );
                        return;
                    }
                    if (!values.onsite_person_mobile) {
                        setCommonError("Please enter Onsite Person Mobile");
                        return;
                    }
                    const kycImageUrl = await uploadImage(kycDoc, AZURE_STORAGE_DIRECTORY.BIOMASS_SUPPLIER, AZURE_STORAGE_SUBDIRECTORY.KYC);


                    const siteImageUrl = await uploadImage(siteImage, AZURE_STORAGE_DIRECTORY.BIOMASS_SUPPLIER, AZURE_STORAGE_SUBDIRECTORY.SITE);
                    const biomassSupplierDetails = {
                        company_address: values.company_address,
                        company_email: values.company_email,
                        company_mobile: values.company_mobile.toString(),
                        owner_kyc_doc_url: kycImageUrl?.data?.publicUrl,
                        onsite_person_name: values.onsite_person_name,
                        onsite_person_mobile: values.onsite_person_mobile.toString(),
                        site_image_url: siteImageUrl?.data?.publicUrl,
                        biomass_type: values.biomass_type.toString(),
                        site_address: values.site_address,
                        lat: values.company_lat.toString(),
                        lng: values.company_lng.toString(),
                    };
                    obj.append(
                        "biomassSupplierDetails",
                        JSON.stringify(biomassSupplierDetails)
                    );
                }

                axiosInstance
                    .post(`/admin/seller/add`, obj, {
                        headers: {
                            ContentType: "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        if (response.status === 201) {
                            setIsSubmitting(false);
                            setSubmitting(false);
                            dispatch(
                                addToast({
                                    kind: SUCCESS,
                                    msg: "Seller Added Successfully!",
                                })
                            );
                            queryClient.invalidateQueries(["getSellers"]);
                            setShowForm(false);
                            dispatch(hideModal());
                        }
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        setSubmitting(false);

                        if (error.response) {
                            const { msg } = error.response.data as {
                                msg: string;
                            };
                            switch (error.response.status) {
                                case 400:
                                case 403:
                                case 500:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `Error: ${msg}`,
                                        })
                                    );
                                    break;
                                case 404:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `No data found for following query`,
                                        })
                                    );
                                    break;
                                default:

                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `Oops, something went wrong`,
                                        })
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`,
                                })
                            );
                        } else {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`,
                                })
                            );
                        }
                    });
            } catch (error) {

                setIsSubmitting(false);
                setSubmitting(false);
                setCommonError("Oops, something went wrong");
                return;
            }
        },
        [dispatch, queryClient, kycDoc, siteImage, files]
    );

    return (
        <>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                        <Form
                            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                            style={{ minWidth: "50vw" }}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                height={100}
                                width={100}
                            />

                            <Input
                                label="Name"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Phone Number"
                                id="phonenumber"
                                name="phonenumber"
                                type="number"
                                placeholder="91 xxxxx xxxxx"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="example@gmail.com"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <div className="mb-0 w-full">
                                <Input
                                    label="Address"
                                    id="address"
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                                <div className="flex mb-4 w-full space-x-4">
                                    <Input
                                        label="City"
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <div className="w-full">
                                        <Select
                                            options={stateArray}
                                            label="State"
                                            id="state"
                                            name="state"
                                        />
                                    </div>
                                    <Input
                                        label="Pin Code"
                                        id="pincode"
                                        name="pincode"
                                        type="number"
                                        placeholder="Pin Code"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                </div>
                                <Input
                                    label="Latitude"
                                    id="lat"
                                    name="lat"
                                    type="text"
                                    placeholder="Latitude"
                                    readOnly
                                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                                <Input
                                    label="Longitude"
                                    id="lng"
                                    name="lng"
                                    type="text"

                                    placeholder="Longitude"
                                    readOnly
                                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                            </div>


                            <div className="w-full h-96 rounded-xl mb-5">
                                <CenterMarker latName="lat" lngName="lng" />
                            </div>


                            <Input
                                label="GST Number"
                                id="gstin"
                                name="gstin"
                                type="text"
                                placeholder="xxxx xxxxx"
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <SelectDropDownAsync
                                label="Product Categories"
                                id="productCategories"
                                name="productCategories"
                                classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                loadOptions={async (inferString: string) => {
                                    try {
                                        const response =
                                            await axiosInstance.get(
                                                "/products/categories"
                                            );
                                        let requiredData: any = [];
                                        for (
                                            let i = 0;
                                            i < response.data.data.length;
                                            i++
                                        ) {
                                            if (true) {
                                                requiredData.push({
                                                    label: response.data.data[i]
                                                        .category,
                                                    value: response.data.data[i]
                                                        .category,
                                                });
                                            }
                                        }
                                        return requiredData;
                                    } catch (error) {
                                        return [
                                            {
                                                label: "Error. Something went wrong!!",
                                                value: "error",
                                            },
                                        ];
                                    }
                                }}
                            />

                            <Input
                                label="Company / Firm / Individual Name"
                                id="companyName"
                                name="companyName"
                                type="text"
                                placeholder="Enter Company / Firm / Individual Name"
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Select
                                label="Company Category"
                                id="companyCategory"
                                name="companyCategory"
                                classes="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                options={[
                                    {
                                        label: "Pvt Ltd.",
                                        value: "pvtLtd",
                                    },
                                    {
                                        label: "Individual",
                                        value: "individual",
                                    },
                                    {
                                        label: "LLP",
                                        value: "llp",
                                    },
                                    {
                                        label: "FPO",
                                        value: "fpo",
                                    },
                                    {
                                        label: "Proprietor",
                                        value: "proprietor",
                                    },
                                ]}
                            />
                            <Input
                                label="Address Proof"
                                id="addressProof"
                                name="addressProof"
                                type="file"
                                // placeholder="Address Proof"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                    if (e.target.files)
                                        setfiles(e.target?.files[0]);
                                }}
                                className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            {role === "admin" && (
                                <Input
                                    label="FPO Unique ID"
                                    id="fpo_id"
                                    name="fpo_id"
                                    type="text"
                                    placeholder="SUN-FPO-XXXXXXXX"
                                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                            )}
                            {values.productCategories.includes("biomass") && (
                                <>
                                    <p>Company Details</p>
                                    <Input
                                        label="Company Address"
                                        id="company_address"
                                        name="company_address"
                                        type="text"
                                        placeholder="XXX Colony, XXXXXX"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Company Email"
                                        id="company_email"
                                        name="company_email"
                                        type="email"
                                        placeholder="example@gmail.com"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Company Mobile"
                                        id="company_mobile"
                                        name="company_mobile"
                                        type="number"
                                        placeholder="xxxxx-xxxxx"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Owner KYC Doc"
                                        id="owner_kyc_doc_url"
                                        name="owner_kyc_doc_url"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e) => {
                                            if (e.target.files)
                                                setKycDoc(e.target?.files[0]);
                                        }}
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Onsite Person Name"
                                        id="onsite_person_name"
                                        name="onsite_person_name"
                                        type="text"
                                        placeholder="eg. Abhinav"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Onsite Person Mobile"
                                        id="onsite_person_mobile"
                                        name="onsite_person_mobile"
                                        type="number"


                                        placeholder="91 xxx-xxx-xxxx"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Site Image"
                                        id="site_image_url"
                                        name="site_image_url"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e) => {
                                            if (e.target.files)
                                                setSiteImage(
                                                    e.target?.files[0]
                                                );
                                        }}
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />

                                    <SelectDropDownAsync
                                        label="Biomass Type"
                                        id="biomass_type"
                                        name="biomass_type"
                                        classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                        loadOptions={async (
                                            inferString: string
                                        ) => {
                                            try {
                                                const response =
                                                    await axiosInstance.get(
                                                        "/biomass_composition/all"
                                                    );
                                                let requiredData: any = [];
                                                for (
                                                    let i = 0;
                                                    i <
                                                    response.data.data.length;
                                                    i++
                                                ) {
                                                    if (true) {
                                                        requiredData.push({
                                                            label: response.data
                                                                .data[i]
                                                                .biomass_name,
                                                            value: response.data
                                                                .data[i]
                                                                .biomass_composition_id,
                                                        });
                                                    }
                                                }
                                                return requiredData;
                                            } catch (error) {
                                                return [
                                                    {
                                                        label: "Error. Something went wrong!!",
                                                        value: "error",
                                                    },
                                                ];
                                            }
                                        }}
                                    />
                                    <Input
                                        label="Site Address"
                                        id="site_address"
                                        name="site_address"
                                        type="text"
                                        placeholder="ABC123"
                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Company Latitude"
                                        id="company_lat"
                                        name="company_lat"
                                        type="text"
                                        placeholder="Latitude"
                                        readOnly

                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <Input
                                        label="Company Longitude"
                                        id="company_lng"
                                        name="company_lng"
                                        type="text"
                                        placeholder="Longitude"
                                        readOnly

                                        className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />

                                    <div className="w-full h-96 rounded-xl mb-10">

                                        <CenterMarker latName="company_lat" lngName="company_lng" />

                                    </div>
                                </>
                            )}

                            {commonError && (
                                <div className="common-error mb-2 text-center">
                                    <ErrorBox msg={commonError} />
                                </div>
                            )}

                            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                                <SubmitBtn
                                    text="Add Seller"
                                    isSubmitting={isSubmitting}
                                    classes="text-sm"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default AddBuyerModal;
